import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  facebook,
  mail_icon,
  nine,
  SD_appstore_icon,
  SD_Call_Icon,
  SD_email_icon,
  sd_map_icon,
  SD_playstore_icon,
  sd_twitter_icon_white,
  SD_Web_Icon,
  send_icon,
  youtube,
} from "../../Assets/imageList";
import NewsletterSubService from "../../services/sd-newsletter-sub.service";
import "../../Utils/sd-styles/sd-footer.scss";
import "react-toastify/dist/ReactToastify.css";
import { SD_Context } from "../../context/sd-context";
import FooterData from "../../Utils/data/footer.data";
import { toast } from "react-toastify";
import pagesData from "../../Utils/data/pages.data";
// eslint-disable-next-line react/display-name
const Footer = React.memo(({ history }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//

  const [selectedLanguage, setSelectedLanguages] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [errorFlag, setErrorFlag] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const { getVisitors, visitorCount } = useContext(SD_Context);
  const [errMsg, setMsg] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const subscribeButton = async () => {
    try {
      if (subEmail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(subEmail).toLowerCase())) {
          setErrorFlag(true);
          setMsg("Please enter a valid Email address");
          return;
        }
        let res = await NewsletterSubService.subscribe(
          subEmail,
          process.env.REACT_APP_TEMPLE ?? "SDMSD"
        );
        if (res) {
          setErrorFlag(false);
          setMsg("");
        }
        // toast.success("Subscribed to newsletter.");
        setSuccessFlag(true);
        setSubEmail("");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setMsg(error.message);
      setErrorFlag(true);

      // toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedLanguages(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedLanguages("en-in");
    }
    getVisitors();
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-footer-main">
      <div className="sd-footer-top">
        <div className="sd-footer-top-right">
          <p>Subscribe Newsletter</p>
          <div className="sd-sub">
            <div
              className={
                errorFlag
                  ? "subscribe sd-red"
                  : subEmail !== ""
                  ? " sd-orange subscribe"
                  : "subscribe"
              }
            >
              <img src={mail_icon} alt="email icon" />

              <input
                type="text"
                maxLength="30"
                placeholder="Email Address"
                style={{ textTransform: "none" }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 || e.key === "Enter") {
                    subscribeButton();
                  }
                }}
                onChange={(e) => {
                  if (errorFlag) {
                    setErrorFlag(false);
                  }
                  setSubEmail(e.target.value);
                }}
                value={subEmail}
              />
              <img
                src={send_icon}
                onClick={subscribeButton}
                alt="newsletter icon"
              />
              {errorFlag && <h4>{errMsg}</h4>}
            </div>
          </div>
        </div>
        <div className="sd-footer-top-left">
          <p>Download Mobile App</p>
          <div>
            <img
              src={SD_playstore_icon}
              alt="google play"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <img src={SD_appstore_icon} alt="google play" />
          </div>
        </div>

        {successFlag && (
          <div className="callout">
            <div className="callout-header">Success</div>
            <span
              className="closebtn"
              onClick={() => {
                setSuccessFlag(false);
              }}
            >
              ×
            </span>
            <div className="callout-container">
              <p>
                Thanks for subscribing!{" "}
                {/* <a >Learn more</a> or close it if it is in your way. */}
              </p>
              <button
                onClick={() => {
                  setSuccessFlag(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="sd-footer-middle">
        <footer className="text-center text-lg-start sd-footer-middle-f">
          <div
            className="sd-container-r"
            style={{
              width: "90%!important",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "initial",
              }}
            >
              <div className="sd-list-body w-16">
                <h5
                  className="text"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/about`);
                  }}
                >
                  About
                </h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/about`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("about")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/about/the-temple/${data?.path}`
                            );
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/sevas-and-darshanam`);
                  }}
                >
                  Sevas & Darshanam{" "}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam`
                        );
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("seva-darshanam")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/sevas-and-darshanam/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text">Donations</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/donations`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("donation")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            toast.info(
                              "Donations info is not available at the moment."
                            );
                            history?.push(
                              `/${selectedLanguage}/about/general-information/${data?.path}`
                            );
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text ">Online Booking</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("online-data")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            if (localStorage.getItem("accessToken")) {
                              history?.push(
                                `/${selectedLanguage}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              history?.push(
                                `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Media Room</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("media")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/media-room/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Support</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("support")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/support/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="sd-list-body w-15">
                <h5 className="text ">Legalities</h5>

                <ul className="list-unstyled">
                  {FooterData("legalities")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            history?.push(`/${selectedLanguage}/${data?.path}`);
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="sd-additional-footer">
            <div className="top">
              <p>
                <img src={sd_map_icon} alt="main" /> {pagesData("home")?.title}
              </p>
              <p>
                <img
                  src={sd_map_icon}
                  alt="main"
                  style={{ visibility: "hidden", marginLeft: "3px" }}
                />
                {pagesData("home")?.address}
              </p>
            </div>
            <div className="bottom">
              <div className="left ">
                <p>
                  <img src={SD_Call_Icon} alt="number" />{" "}
                  {pagesData("home")?.mobile}
                </p>
                <p>
                  <img src={SD_email_icon} alt="mail" className="sep" />
                  {pagesData("home")?.email}
                </p>
                <p>
                  <img
                    src={SD_Web_Icon}
                    alt="mail"
                    style={{ marginLeft: "15px" }}
                  />
                  {pagesData("home")?.link}
                </p>
              </div>

              <div className="right">
                <div className="left">
                  <p>
                    {" "}
                    Visitors Till Today <span>{visitorCount.total ?? 0}</span>
                  </p>{" "}
                </div>
                <div className="left">
                  <p>
                    Visitors Today <span>{visitorCount.today ?? 0}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div className="sd-footer-tag">
        <div className="left">
          {pagesData("navbar")?.socialLinks?.map((item, i) => {
            return (
              <img
                key={i}
                onClick={() => {
                  window.open(item?.link, "_blank");
                }}
                src={item?.icon}
                alt="icon"
              />
            );
          })}
        </div>
        <div className="middle">
          <p className="border-r">
            {" "}
            © {new Date().getFullYear()} {pagesData("home")?.title}. All Rights
            Reserved.
          </p>
          <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/privacy-policy`);
            }}
            className="border-r color"
          >
            Privacy Policy
          </p>

          <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/terms-and-conditions`);
            }}
            className=" color"
          >
            Terms & Conditions
          </p>
        </div>
        <div
          className="right"
          onClick={() => {
            window.open("https://www.9and9.com/", "_blank");
          }}
        >
          Developed by{" "}
          <span>
            {" "}
            <img src={nine} alt="9and9" />
          </span>
        </div>
      </div>
    </div>
  );
});
export default withRouter(Footer);
