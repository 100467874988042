import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFour = ({ history }) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [active, setActive] = useState("news");

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    console.log(selectedlanguage);
  }, []);
  return (
    <>
      <div className="top happenings-bg">
        <h1>Happenings in Temple</h1>
        <img
          src={pagesData("home")?.divider}
          alt="-"
          style={{ height: "20px" }}
        ></img>
      </div>
      <div className="sd-template-four">
        <div className="sd-t4-one">
          <div className="sd-t4-one-start">
            <span className="span">Panchangam</span>
            <img src={pagesData("home")?.panchangam} alt="alt" />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={pagesData("home")?.calendar} />
          </div>
          <div
            style={{ textAlign: "center", margin: "0px" }}
            className="element-panchangam-wrapper"
          >
            <div className="element-panchangam">
              <div className="w-100">
                <p>Samvatsaram</p>
                <h5>Sri Shubhakrut Nama Samvathsaram</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Bhadrapada</p>
                <h5>Sravana</h5>
              </div>
              <div>
                <p>Paksham</p>
                <h5>Krishna</h5>
              </div>
              <div>
                <p>Sunrise</p>
                <h5>05:48 a.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Tithi</p>
                <h5>
                  Dasami upto <br /> 08:21 p.m <br /> afterwards Ekadasi
                </h5>
              </div>
              <div>
                <p>Nakshatram</p>
                <h5>
                  Punarvasu upto
                  <br /> 09:20 p.m <br />
                  afterwards Pushyami{" "}
                </h5>
              </div>
              <div>
                <p>Sunset</p>
                <h5>06:15 p.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Yamagandam </p>
                <h5>
                  9:00 a.m <br /> to 10:30 a.m
                </h5>
              </div>
              <div>
                <p>Rahukalam </p>
                <h5>
                  03:00 p.m <br /> to 04:30 p.m
                </h5>
              </div>
              <div>
                <p>Amruta Gadi</p>
                <h5>
                  06:40 p.m <br />
                  to 08:26 p.m
                </h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Varjyam</p>
                <h5>08:01 a.m to 09:47 a.m</h5>
              </div>
              <div>
                <p>Durmuhurtham</p>
                <h5>08:18 a.m to 09:06 a.m</h5>
                <h5>10:42 p.m to 11:30 p.m</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="sd-t4-one">
          <div className="sd-t4-one-middle">
            <span className="span" style={{ color: "black" }}>
              Day Schedule
            </span>{" "}
            <img
              src={pagesData("home")?.dailySchedule}
              alt="alt"
              style={{ filter: "invert(0)" }}
            />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={pagesData("home")?.calendar} />
          </div>

          <div className="sd-t4-one-end-m">
            {pagesData("home")?.timings?.map((item, i) => {
              return (
                <div key={"sasdasdsd " + i} className="element-date-m">
                  <div>{item.time}</div>
                  <p style={{ textTransform: "uppercase" }}>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sd-t4-one">
          {" "}
          <div className="sd-t4-one-end">
            <span
              className={active === "news" ? "span-active" : ""}
              onClick={() => {
                setActive("news");
              }}
            >
              News
            </span>{" "}
            <span
              className={active === "events" ? "span-active" : ""}
              onClick={() => {
                setActive("events");
              }}
            >
              Events
            </span>{" "}
            <span
              className={active === "tenders" ? "span-active" : ""}
              onClick={() => {
                setActive("tenders");
              }}
            >
              Tenders
            </span>
          </div>
          <div className="sd-t4-one-end-d">
            {active === "news" ? (
              pagesData("home")?.news?.map((item, i) => {
                return (
                  <div key={`sasdasd +${i}`} className="element-date">
                    <div>
                      {item?.month}
                      <br />
                      <span>{item?.date}</span>
                    </div>
                    <p style={{ color: "#171717" }}>{item?.data}</p>
                  </div>
                );
              })
            ) : active === "events" ? (
              pagesData("home").events.map((item, i) => {
                return (
                  <div key={`elementdate +${i}`} className="element-date">
                    <div>
                      {item?.month}
                      <br />
                      <span>{item?.date}</span>
                    </div>
                    <p style={{ color: "#171717" }}>{item?.data}</p>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No {active}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(TemplateFour);
