import React, { useState, useEffect, useRef } from "react";
import {
  SD_cart_icon,
  SD_Print_a_ticket_icon,
  SD_search_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  SD_Sign_In_icon,
  sd_minus_icon,
  sd_plus_icon,
  sd_default_profile_pic,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_booking_history_icon,
  sd_online_booking_icon,
  sd_support,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-navbar.scss";
import moment from "moment";
import { useLocation, withRouter } from "react-router-dom";
import { SD_Context } from "../../context/sd-context";
import Sd_mobile_left_sider from "./sd-mobile-left-sider";
import { toast } from "react-toastify";
import NavBarData from "../../Utils/data/navbar.data";
import pagesData from "../../Utils/data/pages.data";
// import {toast} from "react-toastify";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const New_Nav = React.memo(({ history }) => {
  const {
    setActiveTopHeader,
    activeTopHeader,
    userData,
    language,
    setlanguage,
    setUserData,
    setCurrentDashboardLower,
    currentDashbaordLower,
    logout,
  } = React.useContext(SD_Context);

  //-----------------------------------states-------------------------------------------//

  const [sider, setSider] = useState(false);
  const [rightsider, setrightSider] = useState(false);
  const [drop1, setDropOne] = useState(false);
  const [drop2, setDropTwo] = useState(false);
  const [drop3, setDropThree] = useState(false);
  const [visibleBut, setVisibleBut] = useState(false);
  const [drop4, setDropFour] = useState(false);
  const [drop5, setDropFive] = useState(false);
  const [drop6, setDropSix] = useState(false);
  const [drop7, setDropSeven] = useState(false);
  const [drop8, setDropEight] = useState(false);
  const [drop9, setDropNine] = useState(false);
  const [drop10, setDropTen] = useState(false);
  const [drop11, setDrop11] = useState(false);
  const [drop12, setDrop12] = useState(false);
  const [drop13, setDrop13] = useState(false);
  const [drop14, setDrop14] = useState(false);
  const [drop15, setDrop15] = useState(false);

  const [donationDrop, setDonationDrop] = useState("none");
  const refdonations = useRef(null);
  const refabout = useRef(null);
  const refsevas = useRef(null);
  const refonlinebooking = useRef(null);
  const refmediaroom = useRef(null);
  const refsupport = useRef(null);
  const { pathname } = useLocation();
  const [dropRightone, setDropRightOne] = useState(false);
  const [dropRightTwo, setDropRightTwo] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const refContainer = useRef();
  const [time, setTime] = React.useState(
    moment().utcOffset("+5:30").format(`dddd, DD MMMM YYYY - hh:mm A `)
  );

  //-----------------------------------states-------------------------------------------//

  //-----------------------------------useeffects---------------------------------------//

  useEffect(() => {
    const id = setInterval(timer, 60000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (document.getElementById("myDIV").scrollTop > 1000) {
      setVisibleBut(true);
    } else {
      setVisibleBut(false);
    }
  });

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  useEffect(() => {
    console.log(history.location.pathname.split("/")?.[2]);
    if (history?.location?.pathname?.split?.("/")?.[2] === "about") {
      setCurrentDashboardLower("about");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "home") {
      setCurrentDashboardLower("home");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "sevas-and-darshanam"
    ) {
      setCurrentDashboardLower("Sevas&darshanam");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "donations") {
      setCurrentDashboardLower("Donations");
    } else if (
      history?.location?.pathname?.split?.("/")?.[2] === "online-booking"
    ) {
      setCurrentDashboardLower("online-booking");
    } else if (history?.location?.pathname?.split?.("/")?.[2] === "support") {
      setCurrentDashboardLower("Support");
    } else {
      setCurrentDashboardLower("");
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    refContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [pathname]);

  //-----------------------------------useeffects-------------------------------------------//

  const timer = () => {
    let frist = moment()
      .utcOffset("+5:30")
      .format(`dddd, DD MMMM YYYY - hh:mm A `);
    // console.log(frist);
    setTime(frist + "IST");
  };

  return (
    <div className="sd-navbar-custom" ref={refContainer}>
      <div className="sd-top-nav">
        <div className="sd-top-nav-left">
          {time}
          <div>
            {pagesData("navbar")?.socialLinks?.map((item, i) => {
              return (
                <img
                  key={i}
                  onClick={() => {
                    window.open(item?.link, "_blank");
                  }}
                  src={item?.icon}
                  alt="icon"
                />
              );
            })}
          </div>
        </div>

        <div className="sd-top-nav-right">
          {/* <div
            className={
              activeTopHeader !== "Srisaila-prabha"
                ? "sd-inner-div sd-w-22"
                : "sd-inner-div sd-w-22 sd-selected"
            }
            onClick={() => {
              setActiveTopHeader("Srisaila-prabha");
              history?.push(
                `/${selectedLanguage}/about/the-temple/srisaila-prabha`
              );
            }}
          >
            SRISAILA PRABHA
          </div> */}

          {/*Language Seletor*/}

          <div
            className="sd-dropdown-language"
            style={{ borderLeft: "1px solid #36908A" }}
          >
            <button className="sd-dropbtn-language" type="button">
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="sd-dropdown-content-language"
              aria-labelledby="dropdownMenuButton1"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  setlanguage({ desc: "ENGLISH", code: "en-in" });
                }}
              >
                ENGLISH
              </a>
            </div>
          </div>

          <div
            className={
              activeTopHeader !== "print-a-ticket"
                ? "sd-inner-div sd-w-24"
                : "sd-inner-div sd-w-24 sd-selected"
            }
            onClick={() => {
              history?.push(`/${selectedLanguage}/devotee/print-a-ticket`);
              setActiveTopHeader("print-a-ticket");
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt="icon" />
            PRINT A TICKET
          </div>

          <div
            className={
              activeTopHeader !== "sign-in"
                ? "sd-inner-div sd-w-25"
                : "sd-inner-div sd-selected sd-w-25"
            }
            onClick={() => {
              setActiveTopHeader("sign-in");
            }}
            style={{ borderLeft: "none" }}
          >
            {localStorage.getItem("accessToken") ? (
              userData?.displayName ? (
                <div className="sd-dropdown-signin ">
                  <button className="sd-dropbtn-signin" type="button">
                    <img
                      className="spcl"
                      src={
                        userData?.profilePic !== null
                          ? userData?.profilePic
                          : SD_Sign_In_icon
                      }
                      alt={SD_Sign_In_icon}
                    />{" "}
                    {userData?.displayName !== "" ||
                    userData?.displayName !== null
                      ? userData?.displayName
                      : "User"}
                  </button>
                  <div
                    className="sd-dropdown-content-signin"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <a
                      onClick={() => {
                        history.push(
                          `/${selectedLanguage}/devotee-app/devotee-dashboard`
                        );
                      }}
                    >
                      Dashboard
                    </a>
                    {/* {userData?.role === "admin" && (
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/userview`
                          );
                        }}
                      >
                        User view
                      </a>
                    )} */}
                    <a
                      onClick={() => {
                        logout();
                        history.push(`/${selectedLanguage}/home`);
                      }}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  style={{ color: "white", fontWeight: "600" }}
                  onClick={() => {
                    history?.push(
                      `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }}
                >
                  <img src={SD_Sign_In_icon} alt="icon" /> SIGNIN /SIGNUP
                </div>
              )
            ) : (
              <div
                style={{ color: "white", fontWeight: "600" }}
                onClick={() => {
                  history?.push(
                    `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }}
              >
                <img src={SD_Sign_In_icon} alt="icon" /> SIGN IN / SIGN UP
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sd-bottom-nav">
        <nav className="sd-inner-navbar">
          <div
            className="sd-hamburger-icon"
            onClick={() => {
              if (rightsider) {
                setrightSider(false);
              }
              setSider(true);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className={sider ? "sd-left-sider block" : "sd-left-sider none"}>
            <Sd_mobile_left_sider
              selectedLanguage={selectedLanguage}
              drop1={drop1}
              drop2={drop2}
              setSider={setSider}
              setDropTwo={setDropTwo}
              setDropOne={setDropOne}
              drop3={drop3}
              setDropThree={setDropThree}
              drop4={drop4}
              setDropFour={setDropFour}
              drop5={drop5}
              setDropFive={setDropFive}
              drop6={drop6}
              setDropSix={setDropSix}
              drop7={drop7}
              setDropSeven={setDropSeven}
              drop8={drop8}
              setDropEight={setDropEight}
              drop9={drop9}
              setDropNine={setDropNine}
              drop10={drop10}
              setDropTen={setDropTen}
              drop11={drop11}
              drop12={drop12}
              setDrop11={setDrop11}
              setDrop12={setDrop12}
              drop13={drop13}
              setDrop13={setDrop13}
              drop14={drop14}
              setDrop14={setDrop14}
              drop15={drop15}
              setDrop15={setDrop15}
            />
          </div>

          <div className="sd-inner-left">
            <img
              className="logo"
              src={SD_Logo_circle}
              alt="icon"
              onClick={() => {
                history?.push(`/${selectedLanguage}/home`);
              }}
            />
            <a
              onClick={() => {
                history?.push(`/${selectedLanguage}/home`);
              }}
            >
              Sri Venkateswara
              <br />
              Swamy Vari <br />
              Devasthanam
            </a>
          </div>
          <div className="sd-inner-right">
            <ul className="sd-top-list">
              <li className="item">
                <a
                  className="nav-link"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/volunteer`);
                  }}
                >
                  Volunteer
                </a>
              </li>
              <li className="item">
                <a
                  className="nav-link"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/shop`);
                  }}
                >
                  Shop
                </a>
              </li>
              {window.innerWidth > 600 ? (
                <>
                  <li
                    className="item"
                    onClick={() => {
                      history?.push(`/${selectedLanguage}/cart`);
                    }}
                  >
                    <img
                      src={SD_cart_icon}
                      alt="icon"
                      style={{ cursor: "pointer", filter: "invert(1)" }}
                    />
                  </li>
                  <li
                    className="item sd-spcl"
                    onClick={() => {
                      history?.push(`/${selectedLanguage}/search`);
                    }}
                  >
                    <img
                      src={SD_search_icon}
                      alt="icon"
                      style={{ cursor: "pointer", filter: "invert(1)" }}
                    />
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <ul className="sd-bottom-list">
              <li
                className={
                  currentDashbaordLower === "about"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("about");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "about" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setCurrentDashboardLower("about");
                    history?.push(`/${selectedLanguage}/about`);
                  }}
                >
                  About
                </button>
                {donationDrop === "about" && (
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    ref={refabout}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/about/`);
                        setDonationDrop("none");
                      }}
                    >
                      Overview{" "}
                    </a>
                    <a className="dropdown-item">
                      <a style={{ color: "black", paddingLeft: "0px" }}>
                        The Temple
                      </a>
                      <div className="side-hover">
                        {NavBarData("about")?.["the-temple"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                setDonationDrop("none");

                                history?.push(
                                  `/${selectedLanguage}/about/the-temple/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>

                    <a className="dropdown-item">
                      <a style={{ color: "black", paddingLeft: "0px" }}>
                        Temple Story
                      </a>
                      <div className="side-hover">
                        {NavBarData("about")?.["temple-story"]?.map(
                          (data, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => {
                                  setDonationDrop("none");

                                  history?.push(
                                    `/${selectedLanguage}/about/the-temple-history/${data?.path}`
                                  );
                                }}
                              >
                                {data?.name}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </a>

                    <a className="dropdown-item">
                      <a style={{ color: "black", paddingLeft: "0px" }}>
                        General Information
                      </a>
                      <div className="side-hover">
                        {NavBarData("about")?.["general-information"]?.map(
                          (data, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => {
                                  setDonationDrop("none");

                                  history?.push(
                                    `/${selectedLanguage}/about/general-information/${data?.path}`
                                  );
                                }}
                              >
                                {data?.name}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </a>
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Sevas&darshanam"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                // onClick={() => {
                //   setCurrentDashboardLower("Sevas&darshanam");
                // }}
                onMouseEnter={() => {
                  setDonationDrop("Sevas&darshanam");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={
                    currentDashbaordLower === "Sevas&darshanam"
                      ? "btn active"
                      : "btn"
                  }
                >
                  Sevas & Darshanam
                </button>
                {donationDrop === "Sevas&darshanam" && (
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    ref={refsevas}
                  >
                    {" "}
                    <a
                      style={{ color: "black" }}
                      onClick={() => {
                        setDonationDrop("none");
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam`
                        );
                      }}
                    >
                      Overview
                    </a>
                    <a className="dropdown-item">
                      <a
                        style={{ color: "black", paddingLeft: "0px" }}
                        onClick={() => {
                          setDonationDrop("none");
                          history?.push(
                            `/${selectedLanguage}/sevas-and-darshanam/darshanam`
                          );
                        }}
                      >
                        Darshanam
                      </a>
                      {/* <div className="side-hover">
                        {NavBarData("seva-darshanam")?.["Darshanam"]?.map(
                          (data, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => {
                                  setDonationDrop("none");

                                  history?.push(
                                    `/${selectedLanguage}/sevas-and-darshanam/darshanam/${data?.path}`
                                  );
                                }}
                              >
                                {data?.name}
                              </div>
                            );
                          }
                        )}
                      </div> */}
                    </a>
                    <a className="dropdown-item">
                      <a
                        style={{ color: "black", paddingLeft: "0px" }}
                        onClick={() => {
                          setDonationDrop("none");
                          history?.push(
                            `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva`
                          );
                        }}
                      >
                        Pratyaksha Seva
                      </a>
                      {/* <div className="side-hover">
                        {NavBarData("seva-darshanam")?.["pratyakshaseva"]?.map(
                          (data, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => {
                                  setDonationDrop("none");

                                  history?.push(
                                    `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva/${data?.path}`
                                  );
                                }}
                              >
                                {data?.name}
                              </div>
                            );
                          }
                        )}
                      </div> */}
                    </a>
                    <a className="dropdown-item">
                      <a
                        style={{ color: "black", paddingLeft: "0px" }}
                        onClick={() => {
                          setDonationDrop("none");
                          history?.push(
                            `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva`
                          );
                        }}
                      >
                        Paroksha Seva
                      </a>
                      {/* <div className="side-hover">
                        {NavBarData("seva-darshanam")?.["parokshaseva"]?.map(
                          (data, i) => {
                            return (
                              <div
                                key={i}
                                className="dropdown-item"
                                onClick={() => {
                                  setDonationDrop("none");

                                  history?.push(
                                    `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva/${data?.path}`
                                  );
                                }}
                              >
                                {data?.name}
                              </div>
                            );
                          }
                        )}
                      </div> */}
                    </a>
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Donations"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r "
                }
                onMouseEnter={() => {
                  setDonationDrop("donation");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Donations" ? "btn active" : "btn"
                  }
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/donations`);
                    setCurrentDashboardLower("Donations");
                  }}
                >
                  Donations
                </button>

                {donationDrop === "donation" && (
                  <div className="dropdown-menu" ref={refdonations}>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/donations`);
                        setCurrentDashboardLower("Donations");
                        setDonationDrop(false);
                      }}
                    >
                      Overview
                    </a>
                    {NavBarData("donation")?.map((data, i) => {
                      return (
                        <a
                          className="dropdown-item"
                          key={i}
                          onClick={() => {
                            toast.info(
                              "Donations info is not available at the moment."
                            );
                            history?.push(
                              `/${selectedLanguage}/about/general-information/${data?.path}`
                            );
                            setDonationDrop(false);
                          }}
                        >
                          {data?.name}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "online-booking"
                    ? "dropdown sd-first-button border-r active "
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("online-booking");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "online-booking"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setCurrentDashboardLower("online-booking");
                  }}
                >
                  Online Booking
                </button>
                {donationDrop === "online-booking" && (
                  <div
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                    ref={refonlinebooking}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        setDonationDrop("none");
                        history?.push(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      Overview
                    </a>

                    {NavBarData("online-data")?.map((data, i) => {
                      return (
                        <a
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            setDonationDrop("none");

                            if (localStorage.getItem("accessToken")) {
                              history?.push(
                                `/${selectedLanguage}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              history?.push(
                                `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Media-room"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("media-room");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Media-room"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/media-room`);
                    setCurrentDashboardLower("Media-room");
                  }}
                >
                  Media Room
                </button>
                {donationDrop === "media-room" && (
                  <div
                    className="dropdown-menu sd-media"
                    aria-labelledby="dropdownMenuButton"
                    ref={refmediaroom}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        setDonationDrop("none");
                        setCurrentDashboardLower("Media-room");
                        history?.push(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      Overview
                    </a>

                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/media-kit`
                        );
                        setDonationDrop("none");
                      }}
                    >
                      Media Kit
                    </a>
                    <a className="dropdown-item">
                      <a style={{ color: "black", paddingLeft: "0px" }}>
                        Gallery
                      </a>
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["gallery"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                setDonationDrop("none");

                                history?.push(
                                  `/${selectedLanguage}/media-room/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a
                      className="dropdown-item"

                      // href={`/${selectedLanguage}/media-room/whats-new`}
                    >
                      Whats New{" "}
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                setDonationDrop("none");

                                history?.push(
                                  `/${selectedLanguage}/media-room/whats-new/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a>
                      Press
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["press"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                setDonationDrop("none");

                                history?.push(
                                  `/${selectedLanguage}/media-room/press/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/rti-act`
                        );

                        setDonationDrop("none");
                      }}
                    >
                      RTI Act
                    </a>
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("support");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Support" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setCurrentDashboardLower("Support");
                  }}
                >
                  Support
                </button>
                {donationDrop === "support" && (
                  <div
                    className="dropdown-menu sd-left"
                    ref={refsupport}
                    // aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support`);
                        setCurrentDashboardLower("Support");

                        setDonationDrop("none");
                      }}
                    >
                      Overview{" "}
                    </a>
                    {NavBarData("support")?.map((data, i) => {
                      return (
                        <a
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/support/${data?.path}`
                            );

                            setDonationDrop("none");
                          }}
                        >
                          {data?.name}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="sd-user-slider">
            <img
              src={SD_Sign_In_icon}
              alt="icon"
              onClick={() => {
                if (userData) {
                  if (userData.displayName) {
                    if (sider) {
                      setSider(false);
                    }
                    setrightSider(true);
                  } else {
                    history.push(
                      `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }
                } else {
                  history.push(
                    `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }
              }}
            />
          </div>
          <div
            className={
              rightsider ? "sd-right-sider block" : "sd-right-sider none"
            }
          >
            <div className="inner">
              <div className="inner-nav-top">
                <div className="left">
                  <img
                    className="logo"
                    src={SD_Logo_circle}
                    alt="icon"
                    onClick={() => {
                      if (history) history.push(`/${selectedLanguage}/home`);
                    }}
                  />
                </div>
                <div className="right">
                  <img src={SD_cart_icon} alt={SD_cart_icon} />
                  <button
                    type="button"
                    onClick={() => {
                      setrightSider(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="inner-bottom">
                <div className="inner-bottom-t">
                  <div className="sd-dashboard-profile ml-0">
                    <div className={"profile white-bg"}>
                      <p style={{ marginBottom: "0px" }}>
                        {userData?.displayName === "" ||
                        userData?.displayName === null ? (
                          "User"
                        ) : (
                          <>
                            <span>
                              {" "}
                              {userData.displayName?.split(" ")[1] ? (
                                userData.displayName
                                  ?.split(" ")[0]
                                  ?.charAt(0)
                                  .toUpperCase()
                              ) : (
                                <>
                                  <span>
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(0)
                                      .toUpperCase()}
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(1)}
                                  </span>
                                </>
                              )}
                            </span>
                            <span style={{ marginLeft: "-2px" }}>
                              {userData.displayName?.split(" ")[1]?.charAt(0)}
                            </span>
                          </>
                        )}
                      </p>
                    </div>

                    {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                    <h2
                      className="sd-heading-18 float-right-ellipse c-voilet "
                      style={{ padding: "5px !important" }}
                    >
                      {userData?.displayName}
                    </h2>
                  </div>
                </div>
                <div className="inner-bottom-b">
                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${selectedLanguage}/devotee-app/devotee-dashboard`
                      );
                    }}
                  >
                    <img src={sd_devotee_dashboard} alt="dashboard" />
                    Devotee Dashboard
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightone) {
                        setDropRightOne(false);
                      }
                      setDropRightTwo(!dropRightTwo);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_online_booking_icon} alt="online" /> Online
                      Booking
                    </div>
                    {dropRightTwo ? (
                      <img
                        src={sd_minus_icon}
                        alt="-"
                        onClick={() => {
                          setDropRightTwo(false);
                        }}
                      ></img>
                    ) : (
                      <img
                        src={sd_plus_icon}
                        onClick={() => {
                          setDropRightTwo(true);
                        }}
                      />
                    )}
                  </div>

                  <div
                    className={
                      dropRightTwo
                        ? "drop-right-one block"
                        : "drop-right-one none"
                    }
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      Darshanam
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
                          );
                        }
                      }}
                    >
                      Pratyaksha Seva Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                          );
                        }
                      }}
                    >
                      Paroksha Seva Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/donations`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      Donations
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
                          );
                        }
                      }}
                    >
                      Accommodation
                    </div>{" "}
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        history?.push(
                          `/${selectedLanguage}/devotee-app/online-booking/publications`
                        );
                      }}
                    >
                      Publications
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setrightSider(false);

                      history?.push(
                        `/${selectedLanguage}/devotee-app/booking-history`
                      );
                    }}
                  >
                    <img src={sd_booking_history_icon} alt="booking" /> Booking
                    History
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightTwo) {
                        setDropRightTwo(false);
                      }
                      setDropRightOne(!dropRightone);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_default_profile_pic}></img> My Account
                    </div>
                    {dropRightone ? (
                      <img src={sd_minus_icon} alt="-" />
                    ) : (
                      <img src={sd_plus_icon} alt="+" />
                    )}
                  </div>

                  <div
                    className={
                      dropRightone ? "drop-one block" : "drop-one none"
                    }
                  >
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${selectedLanguage}/devotee-app/my-account/profile`
                        );
                      }}
                    >
                      My Profile
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${selectedLanguage}/devotee-app/my-account/change-password`
                        );
                      }}
                    >
                      Change Password
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${selectedLanguage}/devotee-app/my-account/devotee-masterlist`
                        );
                      }}
                    >
                      Devotee Master List
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        history?.push(
                          `/${selectedLanguage}/devotee-app/my-account/favorites`
                        );
                      }}
                    >
                      My Favorites
                    </div>
                  </div>

                  <div
                    style={{ paddingBottom: "50px" }}
                    onClick={() => {
                      setrightSider(false);

                      history?.push(`/${selectedLanguage}/support`);
                    }}
                  >
                    <img src={sd_support} alt="support" />
                    Support
                  </div>
                </div>
              </div>
              <div
                className="inner-footer"
                onClick={() => {
                  logout();
                  setUserData({});
                  setDropOne(false);
                  setDropTwo(false);
                  setDropRightOne(false);
                  setDropRightTwo(false);
                  setrightSider(false);
                  history.push("/");
                }}
              >
                <img src={sd_logout_icon} alt="Logout" height="15px" />
                <span style={{ marginLeft: "5px", color: "#991403" }}>
                  Logout
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <button
        className={visibleBut ? "active-r" : "hide-r"}
        onClick={() => {
          setVisibleBut(false);
          refContainer.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      >
        <img src={SD_Dropdown_Arrow_icon} />
      </button>
    </div>
  );
});

New_Nav.displayName = "navbar";
export default withRouter(New_Nav);
