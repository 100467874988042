/* eslint-disable react/jsx-pascal-case */
import React from "react";
import SD_Paroksha_Seva_Inner_Component_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-one";
import SD_Paroksha_Seva_Inner_Component_Two from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-two";
import Sd_gallery_semi_view from "../../../Components/sd-common-components/sd-gallery-semi-view";
import Sd_faqs_semi_view from "../../../Components/sd-common-components/sd-faqs-semi-view";
import SD_Paroksha_Seva_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import { SD_Context } from "../../../context/sd-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { Helmet } from "react-helmet";

const Sd_sevas_and_darshanam_paroksha_seva_mahanyasa_purvaka_rudrabhishekam =
  () => {
    //-------------------------------------------------------------states-----------------------------------------------------------//
    const { parokshaSevaData, setParokshaSevaData } =
      React.useContext(SD_Context);
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    //-------------------------------------------------------------states-----------------------------------------------------------//
    //-------------------------------------------------------------useffect-----------------------------------------------------------//

    React.useEffect(() => {
      if (parokshaSevaData.length !== 0) {
        setLoading(true);
        let datas = parokshaSevaData.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "").toLowerCase() ===
            "mahanyasapurvakarudrabhishekam"
        );
        if (datas) {
          setData(datas);
        }
        setLoading(false);
      } else {
        getData();
      }
    }, []);
    //-------------------------------------------------------------useffect-----------------------------------------------------------//
    //-------------------------------------------------------------funtions-----------------------------------------------------------//
    const getData = async () => {
      setLoading(true);
      try {
        let data = await ParokshaSevaService.getAll();
        // console.log(data);
        if (data) {
          setParokshaSevaData(data);
          let datas = data.find(
            (item) =>
              item?.name?.replace?.(/\s/g, "").toLowerCase() ===
              "mahanyasapurvakarudrabhishekam"
          );
          // console.log(datas);
          if (datas) {
            setData(datas);
          }
          setLoading(false);
        } else {
          setLoading(false);
          // console.log("something went wrong");
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    //-------------------------------------------------------------funtions-----------------------------------------------------------//
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Mahanyasa Purvaka Rudrabhishekam in Telugu | Mahanyasa Purvaka
            Rudrabhishekam | Sri Veera Venkata Sathyanaraya Swamy Vari
            Devasthanam Online
          </title>
          <meta
            name="description"
            content="Mahanyasa Purvaka Rudrabhishekam is offered to get rid of hurdles in life. Srisaila Paroksha Seva Mahanyasa Purvaka Rudrabhishekam tickets are available on our Website."
          />
          <meta
            name="keywords"
            content="Mahanyasa Purvaka Rudrabhishekam in Telugu, Mahanyasa Purvaka Rudrabhishekam, Sri Venkateswara Swamy Vari Devasthanam Online"
          />
        </Helmet>
        {loading ? (
          <Sd_loading />
        ) : (
          <div className="w-100">
            <SD_Paroksha_Seva_One
              from={"Mahanyasa Purvaka Rudrabhishekam"}
              data={data}
            />
            <SD_Paroksha_Seva_Inner_Component_One data={data} />
            <SD_Paroksha_Seva_Inner_Component_Two data={data} />
            <Sd_faqs_semi_view from="pratyaksha_seva" />
            <Sd_gallery_semi_view />
          </div>
        )}
      </>
    );
  };
export default Sd_sevas_and_darshanam_paroksha_seva_mahanyasa_purvaka_rudrabhishekam;
