import React from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";

const TemplateFive = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  // const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-six ">
      <div className="sd-t6-one">
        <img src={pagesData("home")?.aboutTemple["img"]} alt="alt" />
      </div>

      <div className="sd-t6-two">
        <div>
          <h1>{pagesData("home")?.aboutTemple["title"]}</h1>
          <img
            src={pagesData("home")?.divider}
            alt="-"
            style={{ height: "20px" }}
          ></img>

          {pagesData("home")?.aboutTemple?.mainDesc?.map((item, i) => {
            return (
              <p style={{ marginTop: "0", marginBottom: "10px" }} key={i}>
                {item}
              </p>
            );
          })}
          {pagesData("home")?.aboutTemple?.smallDesc?.map((item, i) => {
            return (
              <h5 style={{ marginTop: "0", marginBottom: "10px" }} key={i}>
                {item}
              </h5>
            );
          })}
          <div className="temples">
            {" "}
            <div>
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              The Temple
            </div>
            <div>
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              The History
            </div>
            <div>
              <img src={pagesData("home")?.secondaryArrow} height="20px" />
              General Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateFive;
