import React from "react";
import { withRouter } from "react-router-dom";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateTwo = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <>
      {" "}
      <div className="top services-bg">
        <h1>Devotee Services</h1>
        <img
          src={pagesData("home")?.divider}
          alt="-"
          style={{ height: "20px" }}
        ></img>
      </div>
      <div className="sd-template-two">
        {pagesData("home")?.devoteeServices?.map((item, i) => {
          return (
            <div key={i} className="sd-box-one ">
              <div className="sd-box-one-image">
                <img
                  src={item?.img}
                  alt={item?.title}
                  className="sd-logo-img sd-size-four"
                />
              </div>
              <div className="sd-box-one-content">
                <h2>{item?.title}</h2>
                <p>{item?.desc}</p>

                <button
                  className="sd-b-orange"
                  onClick={() => {
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedlanguage}/devotee-app/online-booking/${item?.link}`
                      );
                    } else {
                      history?.push(
                        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/${item?.link}`
                      );
                    }
                  }}
                >
                  {item?.label}{" "}
                  <img src={pagesData("home")?.primaryArrow} alt=">" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default withRouter(TemplateTwo);
