import React from "react";
import { withRouter } from "react-router";
import "../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../Utils/data/pages.data";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_gallery_semi_view = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  return (
    <div className="sd-template-five">
      <div className="top" style={{ background: "white" }}>
        <h1>Photo Gallery</h1>
        <img
          src={pagesData("home")?.divider}
          alt="-"
          style={{ height: "20px" }}
        ></img>
      </div>
      <br></br>
      <div className="sd-photo-grid">
        {pagesData("home")?.photoGallery?.map((item, i) => {
          return (
            <img
              key={i}
              src={item}
              alt={`picture${i}`}
              className={`item${i + 1}`}
            />
          );
        })}
      </div>
      <button
        className="sd-b-orange"
        onClick={() => {
          history?.push(`/${selectedlanguage}/media-room/photo-gallery`);
        }}
      >
        View Now <img src={pagesData("home")?.primaryArrow} alt=">" />
      </button>
    </div>
  );
};
export default withRouter(Sd_gallery_semi_view);
