import {
  SD_annadanam,
  SD_divider,
  SD_gallery_01,
  SD_gallery_02,
  SD_gallery_03,
  SD_gallery_04,
  SD_gallery_05,
  SD_gallery_07,
  accommodation,
  blue_arrow,
  calender,
  donation,
  ganapathi,
  hand_icon,
  homam,
  loader,
  newspaper,
  orange_arrow,
  sd_facebook_icon_white,
  sd_home_banner_1,
  sd_twitter_icon_white,
  sd_youtube_icon_white,
  seshavaham,
  seva_and_darshanam,
  sun_icon,
  template5,
} from "../../Assets/imageList";

const pagesData = (key) => {
  const HomePage = {
    title: "Sri Venkateswara Swamy Vari Devasthanam",
    address: "Dwaraka Tirumala - 534 426. Andhra Pradesh. India.",
    mobile: "+91 8374445062",
    email: "endow-eodtiru-ap@gov.in",
    link: "www.dwarakatirumala.org, aptemples.ap.gov.in",
    marquee: [
      {
        content: "Please watch Paroksha Sevas on Youtube:",
        label: "https://www.youtube.com/@DwarakaTirumalaDevasthanam",
        link: "https://www.youtube.com/@DwarakaTirumalaDevasthanam",
      },
      { content: "test news", label: "Book Now", link: "" },
    ],
    devoteeServices: [
      {
        img: seva_and_darshanam,
        title: "Seva and Darshanam",
        desc: "Darshanam is the auspicious sight of a deity that has immense spiritual significance. The auspicious sight of the deity clears the mind...",
        link: "paroksha-seva",
        label: "Book Now",
      },
      {
        img: SD_annadanam,
        title: "Annadanam",
        desc: "The devotees anywhere from the world can serve the people who are in hunger by donating to Annadanam Trust..",
        link: "donations",
        label: "Donate Now",
      },
      {
        img: accommodation,
        title: "Accommodation",
        desc: "Comfortable and hygienic AC/Non AC Rooms, Suite Rooms, Hotels with all amenities and near to temple to accommodate pilgrims...",
        link: "accommodations",
        label: "Book Now",
      },
      {
        img: donation,
        title: "Donation",
        desc: "e-Hundi allows donations from people across the globe for the welfare of the sacred Sri Venkateswara Swamy. Devotees...",
        link: "donations?to=e-hundi",
        label: "Donate Now",
      },
    ],
    services: [
      {
        img: homam,
        title: "Paroksha Seva",
        desc: "In a world, that's filled with the essence of Narayana, one can now whole heartedly worship Sri Venkateswara Swamy from anywhere in the world...",
        link: "paroksha-seva",
        label: "Book Now",
      },
      {
        img: seshavaham,
        title: "Youtube Channel",
        desc: "Dwarakatirumala Devastanam Official is a Youtube Channel of Sri Venkateswara Swamy Varla Devasthanam where the live telecast of various Sevas will be available…",
        link: "https://www.youtube.com/@DwarakaTirumalaDevasthanamnamLive",
        label: "Watch Now",
      },
    ],
    timings: [
      { time: "04.00 A.M To 11.45 P.M", name: "TEMPLE TIMINGS" },
      { time: "12.00 P.M To 12:30 P.M", name: "Swamy vari Mahanivedhana" },
      { time: "06.00 A.M To 9.00 P.M", name: "SHEEGRA DARSHANAM" },
      { time: "06.00 A.M To 9.00 P.M", name: "ATHI SHEEGRA DARSHANAM" },
    ],
    news: [
      {
        data: "Dussera Celebrations will be started with great grandeur from 25-09-2022",
        month: "SEP",
        date: "09",
      },
      {
        data: "Sri Kanaka Durgamma Temple gearing for 10 day Dussera festivities this year",
        month: "SEP",
        date: "09",
      },
      {
        data: "Temple plans to introduce ₹500 ticket for VIPs",
        month: "SEP",
        date: "12",
      },
      {
        data: "No relinquishment of Bhavani Deeksha atop Indrakeeladri during the festival",
        month: "SEP",
        date: "13",
      },
      {
        data: "The authorities of Sri Venkateswara Swamy Vari Devasthanam (SDMSD) said elaborate arrangements were being made for the annual Dasara festivities, which are going to be celebrated from September 26 to October 5.",
        month: "SEP",
        date: "14",
      },
      {
        data: "Chief minister YS Jagan Mohan Reddy will visit the temple on October 2 on the occasion of Moolanakshatram and offer traditional clothes to the deity on behalf of the state government.",
        month: "SEP",
        date: "19",
      },
    ],
    events: [
      {
        data: "Swarna Kavachalankritha Sri Kanaka Durga Devi alankaram will be on 26-09-2022",
        month: "SEP",
        date: "26",
      },
      {
        data: "Sri Bala Tripura Sundari Devi alankaram will be on 27-09-2022",
        month: "SEP",
        date: "27",
      },
      {
        data: "Sri Gayatri Devi alankaram will be on 28-09-2022",
        month: "SEP",
        date: "28",
      },
      {
        data: "Sri Annapurna Devi alankaram will be on 29-09-2022",
        month: "SEP",
        date: "29",
      },
      {
        data: "Sri Lalitha Tripura Sundari Devi alankaram will be on 30-09-2022",
        month: "SEP",
        date: "30",
      },
      {
        data: "Sri Mahalakshmi Devi alankaram will be on 01-10-2022",
        month: "OCT",
        date: "01",
      },
      {
        data: "Sri Saraswathi Devi (Moola Nakshatram) alankaram will be on 02-10-2022",
        month: "OCT",
        date: "02",
      },
    ],
    photoGallery: [
      SD_gallery_01,
      SD_gallery_03,
      SD_gallery_05,
      SD_gallery_04,
      SD_gallery_02,
      SD_gallery_07,
    ],
    aboutTemple: {
      img: template5,
      title: "About Temple",
      mainDesc: [
        "Dwaraka Tirumala,named after the revered saint Dwaraka, is a sacred pilgrimage center where the self-manifested idol of Lord Sri Venkateswara was discovered by the saint through intense penance in an anthill. Devotees affectionately refer to Sri Venkateswara as Kaliyuga Vaikunta Vasa, signifying his presence in the current age. Also known as Chinna Tirupati, this temple holds a special place as a spiritual hub.",
      ],
      smallDesc: [""],
    },
    divider: SD_divider,
    primaryArrow: orange_arrow,
    secondaryArrow: blue_arrow,
    panchangam: sun_icon,
    calendar: calender,
    dailySchedule: hand_icon,
    idol: ganapathi,
    carousel: [sd_home_banner_1],
    loader: loader,
    newsPaper: newspaper,
  };

  const navbar = {
    socialLinks: [
      {
        icon: sd_facebook_icon_white,
        link: "https://www.facebook.com/DwarakaTirumalaDevasthanam",
      },
      {
        icon: sd_twitter_icon_white,
        link: "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
      },
      {
        icon: sd_youtube_icon_white,
        link: "https://www.youtube.com/@DwarakaTirumalaDevasthanamnamLive",
      },
    ],
  };

  const dataMap = {
    home: HomePage,
    navbar: navbar,
  };

  return dataMap[key];
};

export default pagesData;
