import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import {
  SD_divider,
  sd_minus_icon,
  sd_plus_icon,
  intellectual,
  chairman,
  executive,
  SD_gallery_08,
  SD_Logo_circle,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_loading from "../../sd-common-components/sd-loading";
import Sd_comming_soon from "../../../pages/sd-about/sd-coming-soon";

const Sd_about_general_information_component = ({
  match,
  visible,
  history,
}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [type, setType] = useState(match.params.type);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop6, setDrop6] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const titleRef = useRef();
  const temples = [
    // {
    //   name: "Sri Bhuvaneswari Temple - Kovvur",
    // },
    // { name: "Sri Venu Gopala Swamy Temple - Gedhanapalli" },
    // { name: "Sri Malleswara Swamy Temple - Sankhavaram" },
    // { name: "Sri Satyanarayana Swamy Temple - Yeleswaram" },
  ];

  const dieties = [
    // { name: "Sri Sita Rama Temple", path: "sri-sita-rama-temple" },
    // {
    //   name: "Sri Vana Durga and Kanaka Durga Temple",
    //   path: "sri-vana-durga-and-kanaka-durga-temple",
    // },
    // { name: "Nerallamma Temple", path: "nerallamma-temple" },
  ];
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    setImageLoading(true);

    if (data[type]?.image === "") {
      setImageLoading(false);
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [type]);

  useEffect(() => {
    history?.push(`/${selectedLanguage}/about/general-information/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  const data = {
    "visiting-places": {
      heading: `Visiting Places`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    dieties: {
      heading: `Dieties`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",

      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },

    "sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple": {
      heading: `Sri Anjaneya Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-maragadabika-manikanta-eswara-swamy-temple": {
      heading: `Sri Maragadabika Manikanta Eswara Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-varada-raja-swamy-temple": {
      heading: `Sri Varada Raja Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },

    trusts: {
      heading: `Trusts`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Sri Venkateswara Swamy Vari Devasthanam Trust | Sri Venkateswara Swamy Vari Devasthanam ",
        desc: "Sri Venkateswara Swamy Vari Devasthanam Trust comes under Sri Venkateswara Swamy Vari Devasthanam to collect funds for preserving the ancient shrine. Contribute to Sri Venkateswara Swamy Vari Devasthanam Trust.",
        keywords:
          "Sri Venkateswara Swamy Vari Devasthanam Trust, Sri Venkateswara Swamy Vari Devasthanam ",
        h1_tag: "Sri Venkateswara Swamy Vari Devasthanam Trust",
      },
    },
    "e-hundi": {
      heading: `E Hundi`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
      seo_tag: {
        title:
          "Sri Venkateswara Swamy Vari Devasthanam  | Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        desc: "Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam Trust is a nonprofitable organisation. People make donations to SBMSD Trust on Sri Venkateswara Swamy Vari Devasthanam Online.",
        keywords:
          "Sri Venkateswara Swamy Vari Devasthanam , Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        h1_tag: "Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
      },
    },
    "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust":
      {
        heading: `Saswatha Sri Veera Venkata Sathyanarayana Swamy varla Dharma Prachara
      ParishadNithya Annadanam Trust`,
        description1: <Sd_comming_soon />,
        image: "",
      },
    "sri-krishna-nadi-pavithra-harathi-trust": {
      heading: `Sri Krishna Nadi Pavithra Harathi Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },
    "parirakshana-trust": {
      heading: `Parirakshana Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },
    "gosamrakshana-trust": {
      heading: `Gosamrakshana Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },

    "dos-and-donts": {
      heading: `Do's & Don'ts`,
      description1: (
        <>
          <b style={{ textDecoration: "underline" }}>
            Do’s in Sri Venkateswara Swamy Vari Devasthanam:
          </b>{" "}
          <br />
          -Do observe absolute silence inside the temple and chant Om Namo Sri
          Venkatesaya to yourself.
          <br />
          -Do respect and promote religious sentiments among co-pilgrims.
          <br />
          -Do contact any of the Sri Venkateswara Swami vari Devasthanam’s
          Enquiry Offices for information regarding the temple and your Worship
          there.
          <br />
          -Do pradakshinam (keeping the shrine to your right) with devotion
          before entering the temple.
          <br />
          -Do follow the procedures of the temple and wait for your turn for the
          darshanam of the Lord.
          <br />
          -Do deposit your offerings in the hundi only.
          <br />
          <br />
          <br />
          <b style={{ textDecoration: "underline" }}>
            Don’ts in Sri Venkateswara Swamy Vari Devasthanam:
          </b>
          <br />
          -Don't eat non-vegetarian food. Don't consume liquor or other
          intoxicants.
          <br />
          -Don't wear footwear, or move around in a vehicle, in and around the
          premises of the temple.
          <br />
          -Don't approach touts for quick darshan and accommodation.
          <br />
          -Don't rush in for darshanam, but move along in the queue.
          <br />
          -Don't prostrate (sastanga danda pranamam) inside the temple.
          <br />
          -Don't buy spurious prasadam from street vendors.
          <br />
          -Don't throw away the prasadam and theertham given to you at the
          temple.
          <br />
          -Don't enter the temple, if, according to custom or usage, you are
          prohibited to enter.
          <br />
          -Don't wear flowers and garlands in Gardens; all flowers are for the
          Lord only.
          <br />
          -Don't spit or be a nuisance inside the temple premises.
          <br />
          -Don't enter the shrine naked or wearing only a loin-cloth.
          <br />
          -Don't wear any head guards like helmets, caps, turbans and hats
          inside the temple premises.
          <br />
          -Don't carry any weapon inside the temple.
          <br />
          -Don't eat food brought from outside or chew betel leaves in the
          temple premises.
          <br />
          -Don't evacuate urine or motion or spit in the premises of the temple.
          <br />
          -Don't pay obeisance with only one hand.
          <br />
          -Don't sit with your back facing the Deity.
          <br />
          -Don't talk about your wealth, money and status.
          <br />
          -Don't quarrel in the temple premises.
          <br />
          -Don't commit any act of violence or harshness in the temple premises.
          <br />
          -Don't sleep in the temple premises.
          <br />
          <br />
        </>
      ),
      image: "",
    },
    "intellectual-property": {
      heading: `Intellectual Property`,
      description1: (
        <>
          The materials on this site and any other World Wide Web site/Mobile
          Application owned, operated, licensed or controlled by Sri
          Venkateswara Swamy vari Devasthanam or any of its related, affiliated
          or subsidiary companies, may not be copied, distributed, republished,
          uploaded, posted, linked or transmitted in any way without the prior
          written consent of Sri Venkateswara Swamy vari Devasthanam. All
          material(Logos, trademarks, GI, etc) are protected under copyright,
          trademark and other intellectual property laws. Modification or use of
          the materials for any other purpose than personal/non-commercial use
          violates Sri Venkateswara Swamy vari Devasthanam’s intellectual
          property rights.
        </>
      ),
      image: intellectual,
    },
    certifications: {
      heading: `Certifications`,
      description1: (
        <>
          Sri Venkateswara Swamy Vari Devasthanam is dedicated to aligning its
          practices with the wisdom and spiritual guidance of Yogic principles,
          emphasizing a holistic approach to spiritual support. The commitment
          extends to providing quality services, implementing eco-friendly
          practices, ensuring security measures, and unwavering adherence to
          rituals. This comprehensive approach reflects a dedication to
          physical, mental, and spiritual well-being, environmental
          sustainability, and the safety of visitors. The pursuit of
          certifications underscores the commitment to meeting specific
          standards, further enhancing the overall experience for those seeking
          spiritual guidance at the Devasthanam.
        </>
      ),
      image: SD_gallery_08,
    },
    chairman: {
      heading: `A Word With You`,
      description1: (
        <div style={{ display: "flex" }} className="general-flex">
          <div style={{ textAlign: "center" }}>
            <img src={chairman} height="200px" />
            <h4
              style={{
                padding: "8px 0px",
                color: "#991403",
                fontWeight: "600",
              }}
            >
              Mohan reddy
            </h4>
            <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
            <br />
            As more than 3 to 4 thousand devotees on average days and 7 to 10
            Thousands of Devotees visit Sri Veera Venkata Satyanarayana Swamy
            Temple everyday whereas the devotee flow increases on weekends and
            festival days. With a high level of devotion, people visit the holy
            shrine of Sri Veera Venkata Satyanarayana Swamy vari Devasthanam.
            The Devasthanam organizes efficient systems in providing various
            facilities to pilgrims. The aim of the Devasthanam is always
            improving these amenities.
            <br />
            <br />
            The major amenities for devotees are RO Water supply, Que- complex,
            Dormitories, Choultries, Cottages for accommodation, etc. The
            Devasthanam requests the Devotees to come forward and make liberal
            donations for divine causes and obtain the blessings of Sri Veera
            Venkata Satyanarayana Swamy. The donations can be made through
            Cheque/Demand Draft in favor of The Executive Officer, SVVSS
            Devasthanam, Annavaram - 533406, East Godavari District, Andhra
            Pradesh. The donations can be made through online also. The
            donations given to this Devasthanam are exempt from the Income tax
            under section 80G of the Income Tax Act- 1962.
            <br />
            <br />
            May Sri Veera Venkata Satyanarayana Swamy showers their choicest
            blessings for your long life.
            <br />
            <br />
            <b>
              Chairman <br /> Sri Veera Venkata Satyanarayana Swamy vari
              Devasthanam,
              <br />
              Annavaram
            </b>
          </div>
        </div>
      ),
      image: "",
    },
    executive: {
      heading: `A Word With Devotee`,
      description1: (
        <div style={{ display: "flex" }} className="general-flex">
          <div style={{ textAlign: "center" }}>
            <img src={executive} height="200px" />
            <h4
              style={{
                padding: "8px 0px",
                color: "#991403",
                fontWeight: "600",
              }}
            >
              NAGAVARAPU VENKATA SATYANARAYANA MURTHY
            </h4>
            {/* <h5 style={{ marginBottom: "5px" }}>B.Com, B.L,</h5> */}
            <h5> JOINT COMMISSIONER & EXECUTIVE OFFICER </h5>
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
            <br />
            Annavaram is one of the most famous Holy Shrines in India and enjoys
            second place after Tirupati in Andhra Pradesh. The temple is built
            in the Dravidian style. The glory and richness of Lord SATYADEVA was
            widely described in Revakhanda of Skanda Puranam. The presiding
            deity Lord Satyadeva with his consort Sri Anantha Lakshmi on one
            Side and with Lord Siva on the other side took his abode on
            Ratnagiri, which is named after Ratnakara, son of Meru the king of
            holy mountains. Like in all Divyakshetrams as surrounded and
            followed by holy rivers, Sri Satyanarayana Swamy at Annavaram is
            also followed by the sacred Pampa River touching the feet of the
            hill. Lord Satyadeva the god of truth is showering his eternal
            blessings on mankind in his beautiful manifested form of the DIVINE
            TRINITY namely Hari Hara Hiranya Garbha Thrimurtyatmaka.
            <br />
            <br />
            For the improvements in amenities at the Temple and surrounding
            premises, devotees are requested to donate for the divine cause
            through Cheque/Demand Draft in favor of The Executive Officer, SVVSS
            Devasthanam, Annavaram - 533406, East Godavari District, Andhra
            Pradesh. Right now, the major amenities for devotees are RO Water
            supply, Queue - complex, Dormitories, Choultries, Cottages for
            accommodation, etc. The donations can be made online through our
            website www.annavaramdevasthanam.org
            <br />
            <br />
            The donations given to this Devasthanam are exempt from the Income
            tax under section 80 G of the Income Tax Act- 1962.
            <br />
            <br />
            May Sri Veera Venkata Satyanarayana Swamy showers the blessings for
            the devotees for a healthy and wealthy life.
            <br />
            <br />
            <b>The Executive Officer,</b>
            <br />
            Sri Veera Venkata Satyanarayana Swamy vari Devasthanam,
            <br />
            Annavaram
          </div>
        </div>
      ),
      image: "",
    },
    "adopted-temples": {
      heading: `Adopted Temples`,
      description1: (
        <div className="trust-board-wrapper">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
            <div
              className="sd-seva-booking"
              style={{ justifyContent: "space-between" }}
            >
              {temples?.map((item, i) => {
                return (
                  <div
                    className="seva-div-booking"
                    style={{ padding: "15px", alignItems: "center" }}
                    key={i + "booking-ps"}
                  >
                    <img src={SD_Logo_circle} height="30px" width={"30px"} />
                    <h4 style={{ fontSize: "18px" }}>{item?.name}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ),
      image: "",
    },
    "trust-board": {
      heading: `Board of Trustees`,
      description1: (
        <div className="trust-table">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={SD_Logo_circle} alt="logo" width="250px" />
            <h3>Sri Pusapati Ashok Gajapathi Raju garu</h3>
          </div>
          <table>
            <tr>
              <th>S No</th>
              <th>Name of the Trustee</th>
              <th>Duration</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Sri Pusapati Vijaya Rama Gajapathi Raju garu</td>
              <td>1713-1757</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Sri Pusapati Ananda Gajapathi Raju garu</td>
              <td>1758-1760</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Sri Pusapati Vijaya Rama Gajapathi Raju garu</td>
              <td>1761-1794</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Sri Pusapati Narayana Gajapathi Raju garu</td>
              <td>1795-1845</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Sri Pusapati Vijaya Rama Gajapathi Raju garu</td>
              <td>1846-1879</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Sri Pusapati Ananda Gajapathi Raju garu</td>
              <td>1880-1897</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Sri Pusapati Vijaya Rama Gajapathi Raju garu</td>
              <td>1898-1922</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Sri Pusapati Alak Narayana Gajapathi Raju garu</td>
              <td>1923-1937</td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                Sri Pusapati Vijaya Rama Gajapathi Raju garu (Dr. P.V.G.Raju)
              </td>
              <td>1938-1995</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Sri Pusapati Ananda Gajapathi Raju garu</td>
              <td>1995-2016</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Sri Pusapati Ashok Gajapathi Raju garu</td>
              <td>2016-2020</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Kum. Pusapati Sanchaita Gajapathi Raju garu</td>
              <td>2020-2021</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Sri Pusapati Ashok Gajapathi Raju garu</td>
              <td>2021-----</td>
            </tr>
          </table>
        </div>
      ),
      // description1: (
      //   <div className="trust-board-wrapper">
      //     <div style={{ width: "100%" }}>
      //       <div className="profile-pic" style={{ textAlign: "center" }}>
      //         <img src={chairman} height="200px" />
      //         <h4
      //           style={{
      //             padding: "8px 0px",
      //             color: "#991403",
      //             fontWeight: "600",
      //           }}
      //         >
      //           Mohan reddy
      //         </h4>
      //         <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
      //       </div>
      //     </div>
      //     <div className="flexed">
      //       {[
      //         { image: user2, name: "K Gopi", position: "Member" },
      //         { image: user3, name: "P Govardhan", position: "Member" },
      //         { image: user4, name: "V Susila", position: "Member" },
      //         { image: user5, name: "D Kanthamma", position: "Member" },
      //         {
      //           image: user6,
      //           name: "M Narasimhulu Chetty",
      //           position: "Member",
      //         },
      //         { image: user7, name: "A Prathima", position: "Member" },
      //         { image: userborder, name: "T Lakshmamma", position: "Member" },
      //         { image: user9, name: "K Marutheswar Rao", position: "Member" },
      //         {
      //           image: userborder,
      //           name: "A Subbalakshumma",
      //           position: "Member",
      //         },
      //         { image: userborder, name: "G Kondaiah", position: "Member" },
      //         {
      //           image: user12,
      //           name: "Smt G Venkata Ramanamma",
      //           position: "Member",
      //         },
      //         { image: user13, name: "J Ramsubbamma", position: "Member" },
      //         { image: userborder, name: "B Anitha", position: "Member" },
      //         {
      //           image: user15,
      //           name: "Sri N Somasekhar, Upa Pradhana Archaka Ex-Officio",
      //           position: "Member",
      //         },
      //       ].map((item, i) => {
      //         return (
      //           <div key={"board" + i} className="trust-board">
      //             <img src={item?.image} height="200px" />
      //             <h4
      //               style={{
      //                 padding: "8px 0px",
      //                 color: "#991403",
      //                 fontWeight: "600",
      //               }}
      //             >
      //               {item.name}
      //             </h4>
      //             <h5> {item.position}</h5>
      //           </div>
      //         );
      //       })}
      //     </div>
      //   </div>
      // ),
      image: "",
    },
  };

  const renderLinks = (innerData) => {
    const datas = innerData.map((item, i) => {
      return (
        <div
          key={i + "geenral"}
          className={type === item.path && "sd-active"}
          onClick={() => {
            setType(item.path);
          }}
        >
          {item.name}
        </div>
      );
    });
    console.log(datas);
    return datas;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {data[type]?.seo_tag?.title ??
            "Sri Venkateswara Swamy Vari Devasthanam Official Website"}
        </title>
        <meta
          name="description"
          content={
            data[type]?.seo_tag?.desc ??
            "Sri Venkateswara Swamy Vari Devasthanam Official Website"
          }
        />
        <meta name="keywords" content={data[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              <li
                className={type === "visiting-places" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(false);
                  setDrop1(!drop1);
                }}
              >
                Visiting Places
                {drop1 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop1(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop1(true);
                    }}
                  />
                )}
              </li>
              <div className={drop1 ? "drop1 block" : "drop1 none"}>
                <div
                  className={type === "dieties" ? "main sd-active" : "main"}
                  onClick={() => {
                    setDrop2(!drop2);
                    // setType("dieties");
                  }}
                >
                  <span>Dieties</span>
                  {drop2 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{ marginTop: "6px" }}
                      onClick={() => {
                        setDrop2(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{ marginTop: "6px" }}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop2(true);
                      }}
                    />
                  )}
                </div>
                <div className={drop2 ? "drop2 block" : "drop2 none"}>
                  {renderLinks(dieties)}
                </div>
              </div>
              <li
                className={type === "adopted-temples" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("adopted-temples");
                }}
              >
                Adopted Temples
              </li>
              <li
                className={type === "trusts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(!drop6);
                }}
              >
                <span> Trusts</span>{" "}
                {drop6 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop6(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop6(true);
                    }}
                  />
                )}
              </li>

              <div className={drop6 ? "drop6 block" : "drop6 none"}>
                <div
                  className={type === "e-hundi" && "sd-active"}
                  onClick={() => {
                    setType("e-hundi");
                  }}
                >
                  e-Hundi
                </div>
                {/* <div
                  className={
                    type ===
                      "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType(
                      "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust"
                    );
                  }}
                >
                  Saswatha Sri Veera Venkata Sathyanarayana Swamy varla Dharma
                  Prachara ParishadNithya Annadanam Trust
                </div>
                <div
                  className={
                    type === "sri-krishna-nadi-pavithra-harathi-trust" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType("sri-krishna-nadi-pavithra-harathi-trust");
                  }}
                >
                  Sri Krishna Nadi Pavithra Harathi Trust
                </div>
                <div
                  className={type === "parirakshana-trust" && "sd-active"}
                  onClick={() => {
                    setType("parirakshana-trust");
                  }}
                >
                  Parirakshana Trust
                </div>
                <div
                  className={type === "gosamrakshana-trust" && "sd-active"}
                  onClick={() => {
                    setType("gosamrakshana-trust");
                  }}
                >
                  Gosamrakshana Trust
                </div> */}
              </div>

              <li
                className={type === "executive" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("executive");
                }}
              >
                Executive Officer
              </li>
              <li
                className={type === "dos-and-donts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("dos-and-donts");
                }}
              >
                Do's & Don'ts{" "}
              </li>
              <li
                className={type === "intellectual-property" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("intellectual-property");
                }}
              >
                Intellectual Properties
              </li>
              <li
                className={type === "certifications" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("certifications");
                }}
              >
                Certifications
              </li>
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{ display: imageLoading ? "none" : "block" }}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                <span> {data[type]?.heading}</span> {}
              </h1>
              <img
                src={SD_divider}
                style={{ height: "20px" }}
                alt={"divider"}
              ></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              <div className="sd-athrb-top">
                {data[type]?.image !== "" ? (
                  <img
                    src={data[type]?.image}
                    alt="alternative"
                    align="left"
                    className="sd-imgs"
                    onLoad={() => {
                      setImageLoading(false);
                    }}
                  />
                ) : (
                  ""
                )}
                <p>{data[type]?.description1}</p>
              </div>
              {/* <div className="sd-athrb-bottom">{data[type].description2}</div> */}
            </div>
          </div>
          <div
            style={{
              display: !imageLoading ? "none" : "block",
              height: "30%",
            }}
          >
            <Sd_loading />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Sd_about_general_information_component);
