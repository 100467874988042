import React, { useEffect } from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import pagesData from "../../../Utils/data/pages.data";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateThree = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-three">
      <div className="sd-t3-one">
        <img src={pagesData("home")?.idol} height="100%" />
      </div>
      {pagesData("home")?.services?.map((item, i) => {
        return (
          <div className="sd-t3-two" key={i}>
            {" "}
            <div className="sd-box-one ">
              <div className="sd-box-one-image">
                <img
                  src={item?.img}
                  alt={item?.title}
                  className="sd-logo-img sd-size-four"
                />
              </div>
              <div className="sd-box-one-content">
                <h2>{item?.title}</h2>
                <p>{item?.desc}</p>

                <button
                  className="sd-b-orange"
                  onClick={() => {
                    if (item?.link?.includes("youtube")) {
                      window.open(item?.link, "_blank");
                    } else {
                      if (localStorage.getItem("accessToken")) {
                        history?.push(
                          `/${selectedlanguage}/devotee-app/online-booking/${item?.link}`
                        );
                      } else {
                        history?.push(
                          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/${item?.link}`
                        );
                      }
                    }
                  }}
                >
                  {item?.label}{" "}
                  <img src={pagesData("home")?.primaryArrow} alt=">" />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default TemplateThree;
