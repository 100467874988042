/* eslint-disable react/react-in-jsx-scope */
const paddingStyle = {
  paddingLeft: "40px",
  marginTop: "-23px",
};
export const data = {
  paroksha_seva: [
    {
      question: `What is Paroksha Seva?`,
      answer: `Paroksha Seva is method to perform seva from anywhere of the with a slogan “Worship from Home”`,
    },
    {
      question: `What are the uses of Paroksha Seva?`,
      answer: `By paroksha seva, devotees can be a part of seva from anywhere in the world`,
    },
    {
      question: `What is the difference between Paroksha seva and Pratyaksha Seva?`,
      answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually. For those who can visit the Devasthanam, they will be able to participate in the Seva directly at the temple.`,
    },
    {
      question: `Who should attend Paroksha seva?`,
      answer: `Any devotee can attend Paroksha Seva. `,
    },
    {
      question: `Can unmarried persons attend Paroksha Seva?`,
      answer: `Yes, unmarried people can attend Paroksha Seva. `,
    },
    {
      question: `What are the timings of Paroksha Seva?`,
      answer: `Based on the Seva, Paroksha Seva timings will differ. Seva timings are listed on the Devasthanam website.`,
    },
    {
      question: `Will Paroksha Sevas be performed at the same timings every day?`,
      answer: `Yes. All Paroksha Sevas will be performed during the designated timings.`,
    },
    {
      question: `Will you perform Paroksha seva on auspicious and festival days?`,
      answer: `That varies and the information will be given in our website.`,
    },
    {
      question: `If yes, are there any change of timings in performing Paroksha Seva?`,
      answer: `No. All Paroksha Sevas will be performed during the designated timings. `,
    },
    {
      question: `How to book Paroksha seva? `,
      answer: `Visit our official website www.Dwaraka Tirumaladevasthanam.org to book Paroksha seva`,
    },
    {
      question: `Can we book Paroksha seva offline? `,
      answer: ` No. Paroksha Seva is an online service.`,
    },
    {
      question: `What are the proofs to be submitted to book Paroksha Seva?`,
      answer: `There is no need to submit any proof to book a Paroksha Seva. `,
    },
    {
      question: `How prior can we book Paroksha Seva?`,
      answer: `One may book Paroksha Seva 15 Days prior to performance.`,
    },
    {
      question: `What is the cut off time to book Paroksha Seva?`,
      answer: ` Paroksha Seva can only be booked at least 24 Hrs prior.`,
    },
    {
      question: ` Can we attend Paroksha seva by booking a ticket for Pratyaksha Seva?`,
      answer: `No. Paroksha seva ticket is only applicable for Paroksha Sevas.`,
    },
    {
      question: `Can we attend Pratyaksha Seva by booking a ticket for Paroksha Seva?`,
      answer: `Pratyaksha Seva ticket holders are allowed to utilise the ticket only for Pratyaksha Sevas.`,
    },
    {
      question: `Is there any difference in Seva rituals between Paroksha seva and Pratyaksha seva?`,
      answer: `No.`,
    },
    {
      question: `Will you perform two or more Sevas at the same time?`,
      answer: `No.`,
    },
    // {
    //   question: `If we book tickets for two different Sevas at the same time, how can we watch?`,
    //   answer: `If you've booked two different Sevas, you can watch them live via Annavaram TV Youtube channel.`,
    // },
    {
      question: `Are there any offers available, if we book more Sevas? `,
      answer: ` No. There are no offers available. `,
    },
    {
      question: `What are the rules and regulations to be followed during Paroksha Seva?`,
      answer: `Devotees are requested to take bath and watch the seva live with our youtube link provided.`,
    },
    {
      question: `Will you read-out Gothra-Namas of the whole family with a single ticket?`,
      answer: ` No. Only names of 4 people of a family are recited during the seva.`,
    },
    {
      question: ` With a single ticket, how many Gothrams can be allowed to read?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket.`,
    },
    {
      question: `Will you perform seva to each individual ticket holders separately?`,
      answer: `No.`,
    },
    {
      question: `Will you provide Prasadam for Paroksha seva attendees?`,
      answer: `Yes`,
    },
    {
      question: `How will we know that you sent Prasadam for us?`,
      answer: `Sevadari will get a text message with tracking number for tracking the prasadam`,
    },
    {
      question: `What Prasadam will be sent by you for Paroksha seva attendees?`,
      answer: `That varies with the Seva booked`,
    },
    {
      question: `How will you send Prasadam to our place?`,
      answer: `With the Indian postal address provided during seva booking.`,
    },
    {
      question: `How can we track our Prasadam?`,
      answer: `Sevadari will get a text message with tracking number for tracking the prasadam.`,
    },
    {
      question: `How many days will it take to receive Prasadam?`,
      answer: ` It depends on the distance from Dwaraka Tirumala.`,
    },
    {
      question: `How can we watch Paroksha Seva live?`,
      answer: `With the live link provided to the sevadari.`,
    },
    // {
    //   question: `Without the Internet, is there any possibility for us to watch Paroksha seva on any TV channel (Ex: Bhakti TV)?`,
    //   answer: `No.`,
    // },

    // {
    //   question: `If we miss Paroksha seva live, can we watch the recap?`,
    //   answer: `You may watch the Seva on recap via Devasthanam Official YouTube channel.`,
    // },
    {
      question: `Is there any daily limit for Paroksha Seva booking?`,
      answer: `NO. `,
    },
    {
      question: `Can we cancel the Paroksha Seva ticket?`,
      answer: `No.`,
    },
    {
      question: `Can we transfer our ticket to another person or change the date of Seva?`,
      answer: `No.`,
    },
    {
      question: `Can NRIs book Paroksha seva?`,
      answer: `Yes.`,
    },
    {
      question: `Can NRIs get Prasadam by booking Paroksha Seva?`,
      answer: `No.`,
    },
    {
      question: `How can I provide feedback and suggestions?`,
      answer: `Mail to endow-eosvlnsd@gov.in.`,
    },

    // {
    //   question: `For Further queries and complaints, whom should we contact?`,
    //   answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    // },
  ],
  prak_chandi_homam: [
    {
      question: `What is the significance of Chandi Homam?`,
      answer: `Chandi Devi represents Mahakali, Mahalakshmi and Maha Saraswathi. By performing Chandi Homam, participants get the blessings of all the three goddesses.`,
    },
    {
      question: `What are the timings of Chandi Homam?`,
      answer: `The timings of Chandi Homam are 11:00 a.m To 1:00 p.m.`,
    },
    {
      question: `What is the price of Chandi Homam?`,
      answer: `Chandi Homam costs Rs.1116/-.`,
    },
    {
      question: `How many names and gothrams will be read-out for one ticket during Chandi Homam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during Chandi Homam.`,
    },
    {
      question: `How much time will it take for Homam?`,
      answer: `Chandi Homam takes around 2 Hrs.`,
    },
    {
      question: `What are the formalities to be followed during Seva?`,
      answer: `Devotees are requested to dress in traditional attires for the Homam.`,
    },
    {
      question: `What are the ritual elements used in Homam? `,
      answer: `Ritual elements like Sukandha Dhrayams etc are used for Homam.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `That varies with the Seva booked`,
    },
    {
      question: `Where can I watch Chandi Homam live?`,
      answer: `You may watch Chandi Homam live on Srisaila TV YouTube channel. `,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  online_accomadation: [
    {
      question: `What is the process to book Accommodation?`,
      answer: `Visit the Devasthanam official website for Devasthanam accommodation www.Dwaraka Tirumaladevasthanam.org. Click on Accommodation-select Advanced date and book the accommodation`,
    },
    {
      question: `Is registration required to book accommodation?`,
      answer: `Yes, Registration is required for accommodation booking.`,
    },
    {
      question: `What are the documents to be submitted while booking accommodation?`,
      answer: `Any authorized ID Proof, like Aadhar Card, Driving License etc`,
    },
    {
      question: `Am I supposed to show the original copy of documents submitted during booking at the reception of choultry?`,
      answer: `Yes, you must show the Original Id proof that you've submitted for booking accommodation.`,
    },
    {
      question: `What types of rooms are available in accommodation?`,
      answer: `1. AC rooms, 2. Non AC rooms, 3. Suite rooms, 4. Dormitory`,
    },
    {
      question: `What are the check-in and check-out timings?`,
      answer: `10:00 a.m to next day 10:00 a.m`,
    },
    {
      question: `Is a safety deposit required for checking-in?`,
      answer: `Yes, a safety deposit amount is mandatory for checking-in`,
    },
    {
      question: `Where should I collect my deposit amount while checking-out?`,
      answer: `At the Reception counter.`,
    },
    {
      question: `Is it secure to keep my belongings in the choultry room?`,
      answer: `Yes, but the responsibility must be taken by the devotees only.`,
    },
    {
      question: `Can I change the room you allotted, if I don’t like it?`,
      answer: `Once allotted, it’s not possible to change the room.`,
    },
    {
      question: `How prior should I book accommodation online?`,
      answer: `One may book accommodation 15 days prior only.`,
    },
    {
      question: `Can I book accommodation offline?`,
      answer: `Yes, you may book accommodation offline.`,
    },
    {
      question: `What are the documents to be shown to avail accommodation?`,
      answer: `Documents which are produced during booking online accommodation`,
    },
    // {
    //   question: `How many choultries are available in Srisailam?`,
    //   answer: `A total of 65 choultries are available in Srisailam`,
    // },
    // {
    //   question: `Is there any choultry operating under Devasthanam?`,
    //   answer: `Yes. Choultries operation under Devasthanam for accommodation are available.`,
    // },
    {
      question: `How many rooms can I book within a single registration?`,
      answer: `A single person is allowed to book single accommodation only. There is no restriction on the number of room bookings `,
    },
    // {
    //   question: `Can I choose a specific room of my choice during the accommodation booking?`,
    //   answer: `Yes, you will be able to select a room of your choice during accommodation booking.`,
    // },
    {
      question: `How many persons are allowed to stay in a room?`,
      answer: `Four persons only and single person is not allowed to stay.`,
    },
    {
      question: `Will choultry provide any extra beds, if needed?`,
      answer: `No.`,
    },
    {
      question: `How long can I stay in a choultry room?`,
      answer: `You may stay in a choultry room for 24 Hours.`,
    },

    // {
    //   question: `What are the rules and regulations to be followed to stay in choultries?`,
    //   answer: `Normally one may stay up to two days in a choultry during the off-season. During Peak days, one is allowed to stay in a room for only a single day.`,
    // },
    {
      question: `Can I cancel my accommodation?`,
      answer: `No. Once booked, accommodation can not be cancelled. `,
    },
    // {
    //   question: `Is there any cancellation fee?`,
    //   answer: `No. There is no cancellation fee.`,
    // },
    // {
    //   question: `How prior can I cancel my booking?`,
    //   answer: `Cancellation of a booking is not available.`,
    // },
    {
      question: `For queries and complaints, whom should we contact?`,
      answer: `You may contact the Devasthanam information centre for all your queries and complaints. For more queries, please send a Mail to Devasthanam official e-mail id endow-eosri@gov.in.`,
    },
  ],

  darshanam: [
    {
      question: `What are the timings of Darshanam?`,
      answer: `The Darshanam starts in the Morning from 6:30 AM to 09:00 p.m`,
    },
    {
      question: `Is there any dress code for Darshanam?`,
      answer: `Yes. Pancha or Dhoti for Male and Saree or Half saree for female`,
    },
    {
      question: `What is the price of Seeghra Darshanam?`,
      answer: `The ticket for Seeghra Darshanam costs Rs. 100/-.`,
    },
    {
      question: `What is the price of Athi Seeghra Darshanam?`,
      answer: `The ticket for Athi Seeghra Darshanam costs Rs. 300/-.`,
    },
    {
      question: `Where can I get the Darshanam tickets at Devasthanam?`,
      answer: `At the Devasthanam Darshanam Counters`,
    },
    {
      question: `What is the age limit for kids to purchase a ticket?`,
      answer: `All children above the age of 10 Years must purchase a ticket.`,
    },
    {
      question: `Will I have to carry ID proof for Darshanam?`,
      answer: `Yes, one must carry any Govt. id for Darshanam entry.`,
    },
    {
      question: `What is the process of booking an online ticket?`,
      answer: ` Visit our website: www.Dwaraka Tirumaladevasthanam.org `,
    },
    {
      question: `What documents or ID proofs are required for booking an online ticket for Darshanam?`,
      answer: `Any of the following proofs can be submitted : Aadhar card, Voter ID, PAN card, Driving License.`,
    },
    {
      question: `I don't have the online ticket receipt, what should I do?`,
      answer: `You may visit Devasthanam official website www.srisailamonline.com and reprint the ticket.`,
    },
    {
      question: `After booking tickets online, can I cancel the ticket?`,
      answer: `No. Once booked, the tickets can not be cancelled.`,
    },
    {
      question: `After the online booking, will I be able to change the date of Darshanam?`,
      answer: `No. Please book your dates carefully as once booked, the dates can not be changed.`,
    },
    {
      question: `May I transfer my online ticket to another person?`,
      answer: `No. Transfer of tickets to another person is not allowed.`,
    },
    {
      question: `How much time will it take for Darshanam on weekdays and weekends?`,
      answer: `Two hours for free Darshanam approximately`,
    },
    {
      question: `How much time will it take for Seeghra Darshanam on weekdays and weekends?`,
      answer: `One hour approximately.`,
    },
    {
      question: `How much time will it take for Athi Seeghra Darshanam on weekdays and weekends?`,
      answer: `30 Minutes approximately.`,
    },

    {
      question: `Is there any specific difference between Seeghra and Athi Seeghra Darshanam other than timings?`,
      answer: `No.`,
    },
    {
      question: `May I carry items like mobile, water bottle etc, while going to Darshanam?`,
      answer: `No, `,
    },
    {
      question: `Where should I keep my electronics safe?`,
      answer: `Please deposit your belongings at the Devasthanam clock room.`,
    },
    {
      question: `Do I have to pay fees to keep my electronics safe?`,
      answer: `Yes at cloak room`,
    },
    {
      question: `By buying a ticket, will Devasthanam provide any Prasadam for us?`,
      answer: `No.`,
    },

    {
      question: `Is there any special queue for Handicapped or Postnatal women or pregnant women or aged people?`,
      answer: `Yes, there is a special queue dedicated to Handicapped, Postnatal women, Pregnant  Women & Aged People.`,
    },
    {
      question: `Any specific rules and regulations to follow during Darshanam?`,
      answer: `Follow the dress code, chant Om Namo Narayanaya in the Queue line.`,
    },
    {
      question: `For further queries and complaints, who should we contact?`,
      answer: `Contact via mail : endow-eosvlnsd@gov.in`,
    },
  ],
  donations: [
    {
      question: `What is the limit of donation?`,
      answer: `There is no specific limit to the donation amount. The minimum donation is Rs.1`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All Debit /Credit cards, UPI, Net banking etc. are available for payments.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Kindly contact your Bank for the details`,
    },
    {
      question: `How many days will it take to refund the failed transaction amount?`,
      answer: `Details will be available at your Bank`,
    },
    {
      question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
      answer: `Bank`,
    },
    {
      question: `Can I donate through UPI as I don’t have an internet banking facility?`,
      answer: `Yes, You may donate UPI transaction.`,
    },
    {
      question: `Can I donate with a cheque or DD?`,
      answer: `Yes. Devasthanam accepts Cheques and DD's. For more Information contact Visit Support Section`,
    },
    {
      question: `Can I donate any assets of mine?`,
      answer: `Yes. Please contact the Executive officer to donate your assets.`,
    },
    {
      question: `Can I donate digital gold?`,
      answer: `No. We currently do not accept digital gold as a donation towards Devasthanam.`,
    },
    {
      question: `How will my donation be used?`,
      answer: `In the favor of Temple development.`,
    },
    {
      question: `Can I dedicate my donation to other’s name?`,
      answer: `Yes. You may dedicate your donation to other's name.`,
    },
    {
      question: `Is my donation tax-deductible?`,
      answer: `Yes. Your donation is eligible for tax-deduction.`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Yes. Once you donate, you shall receive an 80G certificate.`,
    },
    {
      question: `Do you trade my details to any other agencies?`,
      answer: `No. We take your privacy and safety seriously. We do not trade your details to any agency.`,
    },
    {
      question: `Will I get any privileges by donating?`,
      answer: `No.`,
    },
    {
      question: `How can I get a receipt of my donation?`,
      answer: `The donation receipt is sent to the email and a bond will be sent via Indian postal to the donor's address.`,
    },
    {
      question: `Can NRIs donate to Devasthanam?`,
      answer: `Yes. NRIs may donate to the Devasthanam.`,
    },
  ],
  online_booking: [
    {
      question: `What documents need to be uploaded during online booking?`,
      answer: `Any Indian Govt.id proof is required for online booking.`,
    },
    {
      question: `What type of cards are accepted in internet banking?`,
      answer: `All Types of Debit/Credit cards are accepted.`,
    },
    {
      question: `Is there a UPI payment facility for online booking?`,
      answer: `Yes. We have provided a UPI payment facility for online booking.`,
    },
    {
      question: `Is it secure to give my personal details and my credit/debit card information for online booking/payments?`,
      answer: `Yes. It is safe to provide your personal details and credit/debit card information online booking/payments.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
    },
    {
      question: `How many days will it take to refund the failed transaction amount?`,
      answer: `It takes around a minimum of 15 days to refund the amount.`,
    },
    {
      question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better`,
    },
    {
      question: `Can I cancel my ticket after online booking?`,
      answer: `No. Once booked, the tickets can not be cancelled.`,
    },
    {
      question: `How can I check my booking details?`,
      answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
    },
    {
      question: `Will I receive any confirmation through SMS or e-mail after online booking?`,
      answer: `Yes. The receipt will be generated after your transaction is completed and confirmation shall be sent via e-mail & SMS.`,
    },
    {
      question: `I didn’t receive a booking confirmation. What should I do?`,
      answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
    },
    {
      question: `Is a change in date of Darshanam/Seva/Accommodation or any online booking service possible?`,
      answer: `No. Please book your dates carefully as once booked, the dates can not be changed.`,
    },
    {
      question: `Can we book a ticket for Darshanam/Accommodation/Sevas on the same day of arrival?`,
      answer: `No, one must book tickets for Darshanam/Accommodation/Sevas at least 24 Hrs prior to arrival.`,
    },
    {
      question: `Can we make multiple bookings?`,
      answer: `Yes. You may make multiple bookings.`,
    },
    {
      question: `With one registration ID, how many tickets can I book?`,
      answer: `You may book up to 10 bookings for each service. But for Paroksha Seva, you can make unlimited bookings.`,
    },
    {
      question: `How much time will it take for booking confirmation?`,
      answer: `If the transaction is successful, the booking is confirmed immediately.`,
    },
    {
      question: `For further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees can attend to all your queries & complaints. They shall record the calls and send them to the concerned department to serve you better.`,
    },
  ],

  saswata_seva: [
    {
      question: `What is Saswata Seva?`,
      answer: `Amongst sevas performed in Sri Venkateswara Swamy Vari Devasthanam, a devotee can choose any seva to
    be performed on a specific date for 10 years on a chosen date.`,
    },
    {
      question: `How many years will you perform Seva on our name with Saswata Seva?`,
      answer: `Saswata Seva is performed for 10 Years Only.`,
    },
    {
      question: `Will you send any Prasadam for us?`,
      answer: `Yes. Prasadam will be sent through Indian Postal to the devotee’s address.`,
    },
    {
      question: `Will we get any privilege by booking Saswata Seva?`,
      answer: `Devotees who've booked Saswata Seva are eligible for donor ID privilege for 10 years
    period. A Donor Card issued in the donor's name facilitates the Darshan for 5 Members.`,
    },
    {
      question: `How will we know that you are performing Saswata Seva in our name?`,
      answer: `A notification will be sent via SMS before 1 Week.`,
    },
    {
      question: `Do I have to pay the whole amount at once?`,
      answer: `Yes. You will have to pay the whole amount in one go which will be received by
    Devasthanam.`,
    },
  ],

  signin_up: [
    {
      question: `What are required proofs for registration?`,
      answer: (
        <>
          {" "}
          You have to provide anyone proof amongst the following list<br></br>
          Aadhar card
          <br />
          Voter card <br />
          Ration card
          <br />
          PAN Card
          <br />
          Passport <br />
          After registering with any ID proof from above listed documents, the
          pilgrim should carry the Original ID proof along with him which was
          submitted during registration.
        </>
      ),
    },
    {
      question: `What is the age limit for registration?`,
      answer: `All people 12 years & above can register on the devasthanam website.`,
    },
    {
      question: `What should I do, if I forget my password?`,
      answer: (
        <div style={paddingStyle}>
          You can reset your password with your registered mobile number or
          e-mail ID. To reset your password, please follow the steps listed
          below:
          <br />
          Click on Forget Password on the login page
          <br />
          {`- `} Your page will be redirected to the Reset your Password screen
          <br />
          {`- `}Enter your registered mobile number
          <br />
          {`- `}Click continue
          <br />
          {`- `}A One Time Password (OTP) will be sent to your mentioned email
          ID or mobile number.
          <br />
          {`- `} Enter the OTP and click continue.
          <br />
          {`- `}You are redirected to the password reset screen.
          <br />
          {`- `}Enter your new password.
          <br />
          {`- `}Re-Enter your new password for confirmation.
          <br />
          {`- `} Click on continue to complete the process.
          <br />
          {`- `} You can now sign in with your new password.
        </div>
      ),
    },
    {
      question: `What if I forget the email address that I used for registration.?`,
      answer: `If you have an existing account, try to login with that account details. But if you forget your
    login credentials, please contact us for help locating your account sign-in information.`,
    },
    {
      question: `What if I didn’t get an OTP?`,
      answer: `Please check that you've entered the correct previously registered mobile number or
    email ID. Otherwise, check your spam or junk section of your email.`,
    },
    {
      question: `Does my email ID acts as a Username?`,
      answer: `The name you enter in the signup process will act as your username.`,
    },
    {
      question: `Do I need to provide my credit/debit card details for registration?`,
      answer: `There is no need to provide any credit/debit card details for registration.`,
    },
    // {
    //   question: `Is registration mandatory for online booking?`,
    //   answer: `No. you can use the guest login.`,
    // },
    {
      question: `Can I cancel my ticket, when I book a ticket through guest login?`,
      answer: `No.`,
    },
    {
      question: `By registration, can I get updates and news of Temple regularly`,
      answer: `Yes. You will receive updates and news of Temple regularly.`,
    },
    {
      question: `For further queries, whom should we contact?`,
      answer: `You can contact Live chat, Customer support and you can contact through Devasthanam e-mail.`,
    },
  ],

  volunteer: [
    {
      question: `How to register as a volunteer?`,
      answer: `Devotees have to visit our website and go to the volunteer page. There you can register as a volunteer.`,
    },
    {
      question: `What is the age limit to register as a volunteer?`,
      answer: `18 years to 60 years.`,
    },
    {
      question: `What are the documents to be submitted to register as a volunteer?`,
      answer: `Any one of Government registered ID proofs such as Aadhar card, driving licence, voter identification card etc.`,
    },
    {
      question: `Can I register offline?`,
      answer: `Yes, You Can Register offline`,
    },
    {
      question: `Are there any accommodation facilities for volunteers?`,
      answer: `Yes. Accommodation will be provided by Devasthanam for volunteers.`,
    },
    {
      question: `What are the sectors available to work as volunteers?`,
      answer: `There are many sections for volunteers to work like Queue, AnnaPrasadam etc.`,
    },
    {
      question: `Can I choose any specific sector to work as a volunteer?`,
      answer: `No. Devasthanam authority will allot the sector rotation basis.`,
    },
    {
      question: `Can we choose to work as volunteers on any specific dates?`,
      answer: `Based on availability of slots you are able to work on specific dates.`,
    },
    {
      question: `Can I work as a volunteer all over the year?`,
      answer: `No.`,
    },
    {
      question: `How can we know that Devasthanam needs a volunteer?`,
      answer: `Devasthanam website will announce the requirement of volunteer in website.`,
    },
    {
      question: `Will you provide any Identity Card for a volunteer?`,
      answer: `Yes. Devasthanam will provide the ID card for every volunteer.`,
    },
    {
      question: `What is the validity of Identity cards?`,
      answer: `It depends on the Utsavams of Sri Venkateswara Swamy Vari Devasthanam.`,
    },
    {
      question: `Can I withdraw my voluntary, after registration?`,
      answer: `Yes.`,
    },
    {
      question: `Can I withdraw my voluntary, after being assigned to work?`,
      answer: `No.`,
    },
    {
      question: `Can I change my voluntary service dates?`,
      answer: `Yes.`,
    },
  ],
  about: [
    {
      question: `What is Dwaraka Tirumala Temple?`,
      answer: `Ans : Dwaraka Tirumala Temple is an abode of Sri Varasiddhi Vinayaka Swamy`,
    },
    {
      question: `Where is Dwaraka Tirumala Temple?`,
      answer: `Ans : Dwaraka Tirumala Temple is located in the Irala Mandal of Chittoor district of the Indian state of Andhra Pradesh. It is at a distance of 12 Kilometers from Chittoor with coordinates 13.2776° North, 79.0355° East.`,
    },
    {
      question: `When was Dwaraka Tirumala Temple discovered?`,
      answer: `Dwaraka Tirumala Temple was discovered above 1100 years ago approximately.`,
    },
    {
      question: `What is the significance of Dwaraka Tirumala Temple?`,
      answer: `Ans : The significance of Dwaraka Tirumala Temple is mentioned above`,
    },

    // {
    //   question: `Why is Srisaila Mallikarjuna Swamy Lingam called as Maha Lingam?`,
    //   answer: `As no other Jyothirlinga temple is surrounded with 8 gateways, only Dwaraka TirumalaMallikarjuna Swamy lingam is mentioned as Mahalingam.`,
    // },
  ],
  "Srisailam-Devasthanam": [
    {
      question: `What is Srisailam?`,
      answer: `Dwaraka Tirumalais an abode  of Sri Bhramaramba sametha Mallikarjuna Swamy who are reformations of Shakthi and Shiva respectively.`,
    },
    {
      question: `Where is Srisailam?`,
      answer: `Dwaraka Tirumalais in Kurnool district of Andhra Pradesh. It is 16.0833 North and 78.8667 East.`,
    },
    {
      question: `When was Dwaraka Tirumala Temple constructed?`,
      answer: `The temple was constructed in the early 11th century CE by the Chola king Kulothunga Chola.`,
    },
    {
      question: `Why is Dwaraka Tirumalaconsidered as best sanctified place for Shaivism and Shakthism?`,
      answer: `As Lord Shiva in Jyothirlinga form and Shakthi in Bramaramba Devi form resided under the same temple court wall like no other temple in the world, devotees consider it as most holy place of Lord Shiva and Parvathi.`,
    },
    {
      question: `How much vast the Dwaraka Tirumalawas at ancient time?`,
      answer: `Dwaraka Tirumalahas 8 gateways from ancient times. The area under these 8 gateways is considered as Srisailam.`,
    },
    {
      question: `What is the significance of Srisailam?`,
      answer: `Dwaraka Tirumalais significant in many aspects and cannot be explained in a single line.`,
    },
    {
      question: `Why is Dwaraka Tirumalacalled Bhukailasam?`,
      answer: `According to Puranas, Lord Shiva himself mentioned Dwaraka Tirumalaas his beloved place after Kailasam and Evan called Dwaraka Tirumalaas Bhukailasam.`,
    },
    {
      question: `Why is Dwaraka Tirumalacalled as Siddha Kshetram?`,
      answer: `All along the ancient periods, many mystics, siddhas, yogis visited and stayed in Srisailam. People believe that invisible siddhas still live in Srisailam. Thus Dwaraka Tirumalais called as Siddha Kshetram.`,
    },
    {
      question: `What are Mathas in Srisailam?`,
      answer: `There are many Mathas in Srisailam. Among them Pancha Mathas are very familiar.`,
    },
    {
      question: `Who built Mathas in Srisailam?`,
      answer: `Maths were not built at a time. Each Matham was built by a king under his dynasty. Most of Mathas were built around the 10th and 11th century A.D..`,
    },
    {
      question: `What are special days and festivals celebrated in Dwaraka Tirumalaevery year?`,
      answer: `Maha Shivaratri and Ugadhi are very special festivals celebrated in Srisailam.`,
    },
    {
      question: `Is Dwaraka Tirumalaunderwent devastation by invaders?`,
      answer: `According to history, Dwaraka Tirumalawas never underwent to devastation.`,
    },
    {
      question: `Did Adhi Shankaracharya visited Srisailam?`,
      answer: `Yes.`,
    },
    {
      question: `Was Soundaryalahari written by Adhi Shankaracharya in Srisailam?`,
      answer: `Yes.`,
    },
    // {
    //   question: `Why is Srisaila Mallikarjuna Swamy Lingam called as Maha Lingam?`,
    //   answer: `As no other Jyothirlinga temple is surrounded with 8 gateways, only Dwaraka TirumalaMallikarjuna Swamy lingam is mentioned as Mahalingam.`,
    // },
  ],

  pratyaksha_seva: [
    {
      question: `What is Pratyaksha seva?`,
      answer: `The seva performed at temple and devotees can participate in person at the temple`,
    },
    {
      question: `What are the uses of Pratyaksha seva?`,
      answer: `Devotees can participate in the Seva as in person at the temple`,
    },
    {
      question: `What is the difference between Paroksha seva and Pratyaksha seva?`,
      answer: ` Paroksha Seva is for those who are unable to participate in Sevas directly. Those who can participate in person at the Temple will be mentioned as Sevadaris of Pratyaksha Seva
      `,
    },
    {
      question: `Who should attend Pratyaksha seva?`,
      answer: `Any devotee can attend Pratyaksha Seva`,
    },
    {
      question: `Is there any age limit to attend Pratyaksha Seva?`,
      answer: `No.`,
    },
    {
      question: `Can unmarried persons attend Pratyaksha seva?`,
      answer: `Yes.`,
    },
    {
      question: `What are the timings of Pratyaksha seva?`,
      answer: `Based on the Seva , Pratyaksha Seva timings will differ.`,
    },
    {
      question: `Is there any dress code to attend Pratyaksha Seva?`,
      answer: `Yes. Pancha or Dhoti for male and Saree or Half Saree for female`,
    },
    {
      question: `What are the rules and regulations to be followed during Pratyaksha seva?`,
      answer: `Proper attire, peaceful mind and soul is enough for offering seva.`,
    },
    {
      question: `Will Pratyaksha sevas be performed at same timings everyday?`,
      answer: `Yes, but varies during special dates like festivals.`,
    },
    {
      question: `What is the reporting time for Pratyaksha Seva?`,
      answer: `Devotee should report 15mins prior any seva.`,
    },
    {
      question: `Will you perform Pratyaksha seva on auspicious and festival days?`,
      answer: `No.`,
    },
    {
      question: `Where can I buy Pratyaksha Seva tickets`,
      answer: `At Devasthanam seva counte.`,
    },
    {
      question: `Can we book Pratyaksha seva online?`,
      answer: `Yes.`,
    },
    {
      question: `What are the proofs to be submitted to book Pratyaksha seva?`,
      answer: `Aadhar card or Driving license or Voter ID or Passport.`,
    },
    {
      question: `How prior can we book Pratyaksha seva?`,
      answer: `Devotee can book pratyaksha seva 15 days prior from the performance date
      `,
    },
    {
      question: `What is the cut off time to book Pratyaksha seva?`,
      answer: ` The seva can be booked before 24 hours from the time of performance`,
    },
    {
      question: `Can we attend Paroksha seva by booking ticket for Pratyaksha seva?`,
      answer: `No. `,
    },
    {
      question: `Will you perform two or more sevas at the same time?`,
      answer: `No.`,
    },
    {
      question: `Are there any offers available, if we book more sevas?`,
      answer: `No.`,
    },
    {
      question: `How many persons are allowed to attend Pratyaksha seva on one ticket?`,
      answer: `Single or Married couple and depends on Seva`,
    },
    {
      question: `Will you perform seva to each individual separately?`,
      answer: `That depends on the seva chosen`,
    },
    {
      question: `What if, Sevadhari is non Telugu speaking person?`,
      answer: `No issues. Our priests will explain in the way devotees understand.`,
    },
    {
      question: `Can Sevadhari adopt native language translator while performing Seva?`,
      answer: `No.`,
    },
    {
      question: `Will you provide Prasadam for Pratyaksha seva attendees?`,
      answer: `It varies with the seva choosen.`,
    },
    {
      question: `What Prasadam will be given by you for Pratyaksha seva attendees?`,
      answer: `It varies with seva chosen`,
    },
    {
      question: `Are there limited tickets for Pratyaksha seva?`,
      answer: `No.`,
    },
    {
      question: `Can we cancel the Pratyaksha seva ticket?`,
      answer: `No.`,
    },
    { question: `Can we transfer our ticket to other person?`, answer: `No.` },
    {
      question: `Can we change our Pratyaksha seva date?`,
      answer: `No.`,
    },
    {
      question: `If any queries and doubts, whom should we contact?`,
      answer: `Contact via mail : endow-eosvlnsd@gov.in.`,
    },
  ],
  media_room: [
    {
      question: `What is a media room?`,
      answer: `The media room is where devotees can find additional information about happenings in the temple, press reports, gallery and other information.`,
    },
    {
      question: `Can I download the files in the media room?`,
      answer: `Sri Venkateswara Swamy Vari Devasthanam is the sole owner of all the content on the website. Downloading or using the content for commercial purposes on our website is strictly prohibited. However, devotees can download the files for non-commercial or devotional purposes.`,
    },
    {
      question: `I am a Photographer and I want to donate my artwork to Srisailam. How can I submit my photographs?`,
      answer: `We accept photographs, videos and artwork from professionals only. You may send your work for donation via email @endow-eosvlnsd@gov.in. After review, If your artwork/photographs/videos are up to our standards, we shall publish them on our website with due credits to the artist`,
    },
    // {
    //   question: `How can I subscribe to e-publications?`,
    //   answer: `You can subscribe to e-publications by registering and paying the prescribed amount of your subscription period.`,
    // },
    {
      question: `How can I receive updates on Dwaraka Tirumala via email?`,
      answer: `Please subscribe to our newsletter via our website. By doing so, you shall receive all the valuable communication via email.`,
    },
    {
      question: `How to send photo/video/movie shooting requests on the premises of the temple?`,
      answer: `How to send photo/video/movie shooting requests on the premises of the temple?.`,
    },
    {
      question: `How to send photo/video/movie shooting requests on the premises of Srisailam?`,
      answer: `All photo/video sessions or movie shooting requests should be sent a minimum of 15 days before by contacting administration department of Sri Venkateswara Swamy Vari Devasthanam`,
    },
    // {
    //   question: `Where can I register for Tenders?`,
    //   answer: `Click the new user icon on the homepage to register under the member's login section.`,
    // },
    {
      question: `From where can I download information and details about specific tenders?`,
      answer: ` Click on your selected tender, you will go to that specific page from where you can download tender details.`,
    },
    {
      question: `Is there a helpline or email address for queries or concerns regarding the media room of Srisailam?`,
      answer: `Please contact our customer support for further queries.`,
    },
  ],
  shop: [
    {
      question: `How do I place an order?`,
      answer: `Browse the products here and click on the product you like. 'Add to cart' and proceed to pay to place orders online.`,
    },
    {
      question: `What is your return policy?`,
      answer: `Once ordered you may not return any products purchased.`,
    },
    {
      question: `What payment modes do you accept?`,
      answer: `We accept all credit/debit cards and net banking when checking out over the phone or online via our secure server.`,
    },
    {
      question: `How do I cancel my order?`,
      answer: `You may not change or cancel an order once placed.`,
    },
    {
      question: `It's been a long since I've ordered and my order hasn't been shipped?`,
      answer: `We try to ship items as soon as possible after ordering. However, Keep your order number ready before you call our helpline- 18004259099 / 9440011717 .`,
    },
    {
      question: `What should I do if products disappear from my cart?`,
      answer: `If products are disappearing from your cart, it may be a problem with your browser's cookies. Please make sure you have your browser set to accept cookies.`,
    },
    {
      question: `Can you fast track my order?`,
      answer: `Unfortunately, we are unable to prioritise deliveries. However, we will make every effort to deliver your purchased product within 5 to 15 working days to the address specified by you during the purchase process.`,
    },
    {
      question: `I tracked my order and it says 'delivered' but I have not received it?`,
      answer: `We ship purchased products to the address provided by the devotee. Please contact the courier partner if our product has not been delivered.`,
    },
    {
      question: `An item is missing from my order?`,
      answer: `Please double-check the invoice contained within your package and ensure all items were successfully ordered. If the invoice confirms the item has been successfully ordered and invoiced however you have not received it, please email @endow-eosri@gov.in or phone 18004259099 / 9440011717 for assistance.`,
    },
    {
      question: `I received an item that is faulty?`,
      answer: `We are committed to providing you with quality products. In the unlikely event you believe the goods to be faulty, please contact our customer support team.`,
    },
    {
      question: `Can I track my order?`,
      answer: `From the ‘Orders’ tab on the website, tap on your order to view its status.`,
    },
    {
      question: `Where can I lodge a complaint or feedback?`,
      answer: `You can give feedback through our feedback form on the website. Contact customer support team brought email or call for further details and complaints.`,
    },
  ],
  cart: [
    {
      question: `How many products can I put in my Cart?`,
      answer: `You can add as many products to your cart.`,
    },
    {
      question: `I put a few products in my cart. They’re not in my cart anymore! What happened?`,
      answer: `Maybe the products are not available anymore or might be a technical glitch. Please contact our helpline - 18004259099 / 9440011717.`,
    },
    {
      question: `What if an item is out of stock?`,
      answer: `We make every effort to keep all products in stock. However, there may be times when unexpected demands exceed our supply. If you place an order for an item that is not in stock, we will inform you through an email and credit your amount within 5-7 business days of purchase.`,
    },
    {
      question: `I want to be notified about the out of stock product in my cart. How?`,
      answer: `Please put the item in save for a later section. When the item is back in stock are allowed to place a new order for this product.`,
    },
    {
      question: `Do I have to sign up to buy something?`,
      answer: `You do not have to create an account when you place an order.`,
    },
    {
      question: `Will everything in my cart ship together?`,
      answer: `Yes. All items in your cart will be shipped together.`,
    },
    {
      question: `Can I ship different items in my cart to different shipping addresses?`,
      answer: `We can only process one shipping address per order. So, if you would like to order several items and ship them to different people, please treat these as separate orders.`,
    },
    {
      question: `Whom should I contact if I have problems with my cart?`,
      answer: `If you have any problems or concerns about your items in your cart, please contact our customer support team.`,
    },
  ],
  support: [
    {
      question: `Do you offer phone support?`,
      answer: `Yes, please ring us at (08668) 238121/25/63 for support.`,
    },
    {
      question: `Do you offer email support?`,
      answer: `Yes. Mail us to endow-eosvlnsd@gov.in for support.`,
    },
    {
      question: `Do you offer chat support?`,
      answer: `Yes, connect with our friendly chat assistant by clicking on chat bot.`,
    },
    // {
    //   question: `It is our first time to Srisailam, can we be assigned a support specialist or a guide for making our visit easy?`,
    //   answer: `We provide detailed information to devotees at Information counter beside main temple of Srisailam.`,
    // },
    {
      question: `Is Annavaram well connected in case of a medical emergency?`,
      answer: `Yes. Sri Venkateswara Swamy Vari Devasthanam organises a hospital for any medical emergency.`,
    },
    {
      question: `Where is the nearest airport?`,
      answer: `Vizag is the nearest airport to reach Annavaram.`,
    },
    {
      question: `Are cab services available at Srisailam?`,
      answer: `Yes, there are plenty of cabs, buses and other public transport facilities.`,
    },
    {
      question: `When to contact your office?`,
      answer: `Everyday, from 09:00 a.m to 05:00 p.m, our staff are available at Temple office.`,
    },
    {
      question: `Are tempo vehicles (12,13 Seaters) and other state vehicles allowed on the ghat road?`,
      answer: `Yes.`,
    },
    {
      question: `How to participate in cultural programmes during festivities?`,
      answer: `Pilgrims who are interested to perform should contact temple office located in Annavaram prior 30 days of celebrations.`,
    },
    {
      question: `Do you have Sri Venkateswara Swamy Vari Devasthanam run cloakrooms for devotees?`,
      answer: `Yes.`,
    },
    // {
    //   question: `Is Dwaraka Tirumalawell facilitated for a month stay?`,
    //   answer: `Yes, you may stay in Dwaraka Tirumalafor more than a month comfortably as it has accommodation with all basic facilities, numerous food joints, shopping markets, hospitals, schools, etc.`,
    // },
    {
      question: `I have other questions, whom should I contact?`,
      answer: `For further details contact our customer support team.`,
    },
  ],
  search: [
    {
      question: `Which deities are worshipped in Srisailam?`,
      answer: `Sri Mallikarjuna Swamy and Bhramarambika devi are the main deitties worshiped in Srisailam.`,
    },
    {
      question: `Is Photography allowed inside the temple?`,
      answer: `No, photography is prohibited inside the temple.`,
    },
    {
      question: `I see devotees bringing fruits/milk and placing it on the platform near the Deities. What does it mean?`,
      answer: `It is called Naivedyam, a token of gratitude to God.`,
    },
    {
      question: `What is the dress code of the temple?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `I see people walking in the prayer hall in a clockwise direction. What does it mean?`,
      answer: `It is called Circumambulation, a way to offer his/her prayers to his/her deity.`,
    },
  ],
  sevas_darshanam: [
    {
      question: `Is there any dress code for Darshanam?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What is the price of Seeghra Darshanam?`,
      answer: `The ticket for Seeghra Darshanam costs Rs. 150/-.`,
    },
    {
      question: `What is the price of Athi Seeghra Darshanam?`,
      answer: `The ticket for Athi Seeghra Darshanam costs Rs. 500/-.`,
    },
    {
      question: `Where can I get the Darshanam tickets at Devasthanam?`,
      answer: `One may purchase Darshanam tickets at the Devasthanam offline counters.`,
    },
    {
      question: `What is the age limit for kids to purchase a ticket?`,
      answer: `All children above the age of 10 Years must purchase a ticket.`,
    },
    {
      question: `Will I have to carry ID proof for Darshanam?`,
      answer: `Yes, one must carry any Govt. id for Darshanam entry.`,
    },
    {
      question: `Can we attend Pratyaksha Seva by booking a ticket for Paroksha Seva?`,
      answer: `Pratyaksha Seva ticket holders are allowed to utilise the ticket only for Pratyaksha Sevas.`,
    },
    {
      question: `What is the difference between Paroksha seva and Pratyaksha Seva?`,
      answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually. For those who can visit the Devasthanam, they will be able to participate in the Seva directly at the temple.`,
    },
    {
      question: `What is the significance of Paroksha Seva?`,
      answer: `Paroksha Seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually.`,
    },
    {
      question: ` How to book Paroksha seva? `,
      answer: `You may book Paroksha Seva via Devasthanam official website www.srisailadevasthanam.org, on the Paroksha Seva page. `,
    },
    {
      question: `Can we book Paroksha seva offline? `,
      answer: ` No. Paroksha Seva is an online service.`,
    },
    {
      question: `What are the proofs to be submitted to book Paroksha Seva?`,
      answer: `There is no need to submit any proof to book a Paroksha Seva. `,
    },
    {
      question: `How prior can we book Paroksha Seva?`,
      answer: `One may book Paroksha Seva 15 Days prior.`,
    },
    {
      question: `What are the timings of Pratyaksha seva?`,
      answer: `Based on the Seva , Pratyaksha Seva timings will differ. Seva timings are listed on the Devasthanam website..`,
    },
    {
      question: `Is there any dress code to attend Pratyaksha Seva?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What are the rules and regulations to be followed during Pratyaksha seva?`,
      answer: `Proper attire, peaceful mind and soul is enough for offering seva.`,
    },
    {
      question: `Will Pratyaksha sevas be performed at same timings everyday?`,
      answer: `Yes, but varies during special dates like festivals.`,
    },
  ],
};
